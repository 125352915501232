const INITIAL_STATE = {
    patients: [],
    favorites: [],
    user: {},
    city_select: "Алматы",
    city_open: false,
    city_open_adv: false,
    token_: false,
};


const stateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'STATE':
            return {...state, ...action.value};
        default:
            return state;
    }
};
export default stateReducer;
