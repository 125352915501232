import './style.scss'

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";
import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import SelectSearch from 'react-select';
import Modal from "react-modal";
import {getAdv, getReg, getUser, sendFile, updateDB} from "../../services";
import {NotificationManager} from "react-notifications";
import Adv from "../../components/Adv";

const MainScreen = (() => {

    const state_in = useSelector(StateSlices);

    const [info, setInfo] = useState('У вас нет запросов');
    const [page, setPage] = useState(0);

    const [registerModal, setRegisterModal] = useState(false);

    const [selectVal, setSelectVal] = useState(state_in.user);

    const [product, setProduct] = useState([]);
    const [selectCatalog, setSelectCatalog] = useState('all');


    async function getAdvEf() {

        setProduct((await getAdv()).data);
        State({
            user: (await (getUser(state_in.token))).message
        })
    }

    useEffect(() => {
        getAdvEf();

    }, []);

    const city = [
        'Астана',
        'Алматы',
        'АКТАУ',
        'АКТОБЕ',
        'АЛМАТЫ',
        'АТЫРАУ',
        'БАЙКОНУР',
        'БАЛХАШ',
        'БОРОВОЕ',
        'ЖЕЗКАЗГАН',
        'КАРАГАНДА',
        'КОКШЕТАУ',
        'КОСТАНАЙ',
        'КЫЗЫЛОРДА',
        'ПАВЛОДАР',
        'ПЕТРОПАВЛОВСК',
        'СЕМЕЙ',
        'ТАЛДЫКОРГАН',
        'ТАРАЗ',
        'ТУРКЕСТАН',
        'УРАЛЬСК',
        'УСТЬ-КАМЕНОГОРСК',
        'ШЫМКЕНТ',
    ];

    const city2 = [
        'Москва',
        'Санкт-Петербург',
        'Абаза',
        'Абакан', 'Абдулино', 'Абинск', 'Агидель', 'Агрыз', 'Адыгейск', 'Азнакаево', 'Азов', 'Ак-Довурак', 'Аксай', 'Алагир', 'Алапаевск', 'Алатырь', 'Алдан', 'Алейск', 'Александров', 'Александровск', 'Александровск-Сахалинский', 'Алексеевка', 'Алексин', 'Алзамай', 'Алупка', 'Алушта'
    ];

    const city3 = [
        'Киев',
        'Одесса',
    ];


    const city_select = [
        {
            label: 'Казахстан',
            options:
                city.map((city_del, index) => {
                    const lest = city_del.toLowerCase().split('');
                    lest[0] = lest[0].toUpperCase()
                    return {label: lest.join(""), value: (index + 1) + 'kz'};
                })
        },
        {
            label: 'Россия',
            options:
                city2.map((city_del, index) => {
                    const lest = city_del.toLowerCase().split('');
                    lest[0] = lest[0].toUpperCase()
                    return {label: lest.join(""), value: (index + 1) + 'ru'};
                })
        },
        {
            label: 'Украина',
            options:
                city3.map((city_del, index) => {
                    const lest = city_del.toLowerCase().split('');
                    lest[0] = lest[0].toUpperCase()
                    return {label: lest.join(""), value: (index + 1) + 'uk'};
                })
        },
    ];

    const auth_step = [
        {type: 'text', required: true, name: 'name', label: 'ФИО', value: ''},
        {
            type: 'select',
            required: true,
            name: 'city_id',
            label: 'Город',
            value: '',
            placeholder: "Поиск...",
            options: city_select
        },
        {type: 'date', required: false, name: 'date_id', label: 'Дата рождения', value: ''},
        {type: 'text', required: false, name: 'tel', placeholder: '+7 777 777 7777', label: 'Телефон', value: ''},
        {type: 'text', required: false, name: 'iin', label: 'ИИН', value: ''},
        {type: 'file', required: false, name: 'image', label: 'Фото', value: ''},
    ];

    function showModal() {
        setRegisterModal(false);
    }

    return (
        <Layout>
            <Modal
                isOpen={registerModal}
                onRequestClose={showModal}
                contentLabel="Example Modal"
                className="model-add-box model-add-box-register"
            >

                <form method={'post'} onSubmit={async (item) => {
                    item.preventDefault()


                    let json = Object.entries(selectVal).filter((isd) => {
                        return true;
                    }).reduce(function (previous, key) {
                        if (key[1]?.label) {
                            return {...previous, ...{[key[0].replace("_adv", "")]: key[1].label}};
                        }
                        return {...previous, ...{[key[0].replace("_adv", "")]: key[1]}};
                    }, {});

                    await updateDB({
                        'save': json,
                        'id': state_in.user.id,
                        'model_name': 'User',
                    });
                    State({
                        user: (await (getUser(state_in.token))).message
                    })
                    NotificationManager.success('Профиль обновлен', '');
                    showModal();
                    return false;
                }} className="model-add">
                    <div className="model-add_nav">
                        <a href="javascript:void(0)" onClick={() => {
                        }} className="btn btn-nav-link text text-s18"
                           style={{fontWeight: 500, color: '#333', width: "100%"}}>
                            Редактирование профиля
                        </a>
                    </div>

                    <div className="model-add_body">

                        {
                            (
                                auth_step.map((item) => {

                                        if (item.type == 'select') {
                                            return (
                                                <div className="input input-wrap">
                                                    <p dangerouslySetInnerHTML={{__html: item.label}}
                                                       className="input_lab input_lab-full text text-s14">
                                                    </p>
                                                    <SelectSearch options={item?.options} required
                                                                  onChange={(test) => {
                                                                      setSelectVal({
                                                                          ...{
                                                                              ...selectVal,
                                                                              ...{
                                                                                  [item.name + '']: (test)
                                                                              }
                                                                          }
                                                                      });
                                                                  }} search={true}
                                                                  value={selectVal[item.name + '']}
                                                                  className={'text text-s12'} name={item.name}
                                                                  placeholder={item?.placeholder}/>

                                                </div>
                                            )
                                        } else if (item.type == 'date') {
                                            return (
                                                <div className="input input-wrap">
                                                    <p dangerouslySetInnerHTML={{__html: item.label}}
                                                       className="input_lab input_lab-full text text-s14">
                                                    </p>
                                                    <input onChange={(ev) => {
                                                        setSelectVal({
                                                            ...{
                                                                ...selectVal,
                                                                ...{
                                                                    [item.name + '']: (ev.target.value)
                                                                }
                                                            }
                                                        });
                                                    }} type="date" placeholder={item?.placeholder}
                                                           required={item?.required}
                                                           value={selectVal[item.name + '']}
                                                           className="input_in text text-s16"/>
                                                </div>
                                            )
                                        } else if (item.type == 'file') {
                                            return (
                                                <div className="input input-wrap">
                                                    <p dangerouslySetInnerHTML={{__html: item.label}}
                                                       className="input_lab input_lab-full text text-s14">
                                                    </p>
                                                    {
                                                        typeof selectVal[item.name + ''] != 'undefined' && selectVal[item.name + ''] &&
                                                        (
                                                            <img
                                                                style={{
                                                                    width: "10rem",
                                                                    marginBottom: "1rem"
                                                                }}
                                                                src={'https://admin.disconty.kz' + selectVal[item.name + '']}/>
                                                        )

                                                    }
                                                    <input onChange={async (event) => {
                                                        var reader = new FileReader();
                                                        reader.readAsBinaryString(event.target.files[0]);
                                                        reader.onload = async () => {
                                                            setSelectVal({
                                                                ...{
                                                                    ...selectVal,
                                                                    ...{
                                                                        [item.name + '']: (await sendFile({
                                                                            "files": btoa(reader.result)
                                                                        })).data
                                                                    }
                                                                }
                                                            });
                                                        };
                                                        reader.onerror = function (error) {
                                                            console.log('Error: ', error);
                                                        };
                                                    }} type="file" name={item.name} required={item?.required}
                                                           placeholder={item?.placeholder}
                                                           className="input_in text text-s16"/>
                                                </div>
                                            )
                                        } else if (item.type == 'pass') {
                                            return (
                                                <div className="input input-wrap">
                                                    <p dangerouslySetInnerHTML={{__html: item.label}}
                                                       className="input_lab input_lab-full text text-s14">
                                                    </p>
                                                    <input onChange={(ev) => {
                                                        setSelectVal({
                                                            ...{
                                                                ...selectVal,
                                                                ...{
                                                                    [item.name + '_reg']: (ev.target.value)
                                                                }
                                                            }
                                                        });
                                                    }} type="password" value={selectVal[item.name + '']}
                                                           required={item?.required} name={item.name}
                                                           placeholder={item?.placeholder}
                                                           className="input_in text text-s16"/>
                                                </div>
                                            )
                                        }

                                        return (
                                            <div className="input input-wrap">
                                                <p dangerouslySetInnerHTML={{__html: item.label}}
                                                   className="input_lab input_lab-full text text-s16">
                                                </p>
                                                <input onChange={(ev) => {
                                                    setSelectVal({
                                                        ...{
                                                            ...selectVal,
                                                            ...{
                                                                [item.name + '']: (ev.target.value)
                                                            }
                                                        }
                                                    });
                                                }} type="text" value={selectVal[item.name + '']} name={item.name}
                                                       required={item?.required}
                                                       placeholder={item?.placeholder}
                                                       className="input_in text text-s16"/>
                                            </div>
                                        )
                                    }
                                )
                            )
                        }
                    </div>
                    <button type={'submit'} style={{backgroundColor: 'transparent', border: "none"}}
                            className={'next-auth-form text text-s20'}>
                        сохранить
                    </button>
                </form>
            </Modal>


            <div className="container">
                <div className={'profile-box'}>
                    <div className={'profile'}>
                        <div className="profile-prop">
                            <div className="profile-prop-src" style={{
                                backgroundImage: "url('https://admin.disconty.kz" + state_in?.user?.image + "')"
                            }}>

                            </div>
                        </div>
                        <div className="profile-text">
                            <div className="profile-text__item">
                                <p className={'text text-bold text-s32'}>
                                    {state_in?.user?.name}
                                </p>
                            </div>
                            <div className="profile-text_body">
                                <div className="profile-isinfo">
                                    <div className="profile-text__item">
                                        <p className={'text text-bold text-s16'}>
                                            ИИН:
                                        </p>
                                        <p className={'text text-s16'}>
                                            {state_in?.user?.iin}
                                        </p>
                                    </div>
                                    <div className="profile-text__item">
                                        <p className={'text text-bold text-s16'}>
                                            Город:
                                        </p>
                                        <p className={'text text-s16'}>
                                            {state_in?.user?.city_id}
                                        </p>
                                    </div>
                                    <div className="profile-text__item">
                                        <a onClick={() => {
                                            setRegisterModal(true);
                                        }} style={{color: "#415669"}} className={"  cli text text-s14 "}>
                                            Редактировать
                                        </a>
                                    </div>

                                </div>
                                <div className="profile-isinfo">
                                    <div className="profile-text__item">
                                        <p className={'text text-bold text-s16'}>
                                            Рейтинг:
                                        </p>
                                        <p className={'text text-s16'}>
                                            {state_in?.user?.rait}
                                        </p>
                                    </div>
                                    <div className="profile-text__item">
                                        <p className={'text text-bold text-s16'}>
                                            Номер телефона:
                                        </p>
                                        <p className={'text text-s16'}>
                                            {state_in?.user?.tel}
                                        </p>
                                    </div>
                                    <div className="profile-text__item">
                                        <a onClick={() => {
                                            State({token: false, user: {}, user_id: {}});
                                            location.pathname = "/";
                                        }} style={{color: "#415669"}}
                                           className={" cli text text-s14 "}>
                                            Выйти с аккаунта
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="profile-text__item" style={{display: "flex"}}>*/}
                            {/*    <p className={'text text-w700 text-s16'}>*/}
                            {/*        Мои запросы - {product.filter((isr) => {*/}
                            {/*        return isr.user_id.id == state_in?.user?.id && isr?.status != 'done';*/}
                            {/*        // return isr.include() search*/}
                            {/*    }).length}*/}
                            {/*    </p>*/}
                            {/*    <p style={{margin: "0 0.5rem"}}></p>*/}
                            {/*    <p className={'text text-w700 text-s16'}>*/}
                            {/*        Участвую в {product.filter((isr) => {*/}
                            {/*        let status = (isr.user_list.filter((itemsa) => {*/}
                            {/*            return itemsa.user_id.id == state_in.user.id && isr?.status != 'done';*/}
                            {/*        })).length > 0;*/}
                            {/*        return status;*/}
                            {/*        // return isr.include() search*/}
                            {/*    }).length} запросах*/}


                            {/*    </p>*/}
                            {/*</div>*/}
                            {/*<div className="profile-text__item" style={{display: "flex", marginTop: "0.5rem"}}>*/}
                            {/*    <a onClick={() => {*/}
                            {/*        setRegisterModal(true);*/}
                            {/*    }} style={{color:"#415669"}} className={"  cli text text-s14 "}>*/}
                            {/*        Редактировать профиль*/}
                            {/*    </a>*/}


                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="profile-text-mini">

                    </div>
                </div>
                <div className="profile-adv">
                    <div className="catalog_list">
                        <a onClick={() => {
                            setPage(0);
                            setInfo('У вас нет запросов');
                        }}
                           className={"btn btn-catalog btn-catalog-big cli text text-s15 " + (page == 0 && 'active')}>
                            Мои запросы
                        </a>
                        <a onClick={() => {
                            setPage(1);
                            setInfo('Вы не участвуете в запросах');
                        }}
                           className={"btn btn-catalog btn-catalog-big cli  text text-s15 " + (page == 1 && 'active')}>
                            Участвую в запросах
                        </a>
                        <a onClick={() => {
                            setPage(2);
                            setInfo('У вас нету завершённых запросов!');
                        }}
                           className={"btn btn-catalog btn-catalog-big cli  text text-s15 " + (page == 2 && 'active')}>
                            Завершенные запросы
                        </a>
                        <a onClick={() => {
                            setPage(3);
                            setInfo('У вас нету завершённых запросов!');
                        }}
                           className={"btn btn-catalog btn-catalog-big cli  text text-s15 " + (page == 3 && 'active')}>
                            Избранные
                        </a>
                    </div>

                    <div className="profile-adv-res">
                        <div className="catalog_main catalog_main-profile">
                            {
                                page == 0 && (
                                    [...product].filter((isr) => {
                                        return isr.user_id.id == state_in?.user?.id && isr?.status != 'done';
                                    }).map((item) => {
                                        return (
                                            <Adv itemOpen={item}/>
                                        );
                                    })
                                )
                            }


                            {
                                page == 1 && (
                                    [...product].filter((isr) => {
                                        return ((isr.user_list.filter((itemsa) => {
                                            return itemsa.user_id.id == state_in.user.id;
                                        })).length > 0) && isr?.status != 'done';
                                        // return isr.include() search
                                    }).map((item) => {
                                        return (
                                            <Adv itemOpen={item}/>
                                        );
                                    })
                                )
                            }


                            {
                                page == 2 && (
                                    [...product].filter((isr) => {
                                        return isr.user_id.id == state_in?.user?.id && isr?.status == 'done';
                                        // return isr.include() search
                                    }).map((item) => {
                                        return (
                                            <Adv itemOpen={item}/>
                                        );
                                    })
                                )
                            }

                            {
                                page == 3 && (
                                    state_in.favorites.map((item) => {
                                        return (
                                            <Adv itemOpen={item}/>
                                        );
                                    })
                                )
                            }



                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
});
export default MainScreen;
