import './style.scss'

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";
import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import SelectSearch from 'react-select';
import {deleteDBAdvUser, getAdv, getAdvOpen, getCategory, getChat, getPayBox, updateDB} from "../../services";
import Adv from "../../components/Adv";

import One from "../../assets/images/1.png";
import Two from "../../assets/images/2.png";
import TreeImg from "../../assets/images/3.png";
import AdvItem from "../../components/Adv/components/AdvItem";
import {NotificationManager} from "react-notifications";
import StarFull from "../../assets/images/star_full.png";
import StarEmpty from "../../assets/images/star_empty.png";
import Modal from "react-modal";

const MainScreen = (() => {
    const state_in = useSelector(StateSlices);
    const [select, setSelect] = useState('');
    const [filter, setFilter] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [rait, setFilterRaiti] = useState(false);

    const [citydId, setCityId] = useState(state_in.city_select);

    const [product, setProduct] = useState([]);

    const [category, setCategory] = useState([]);


    const [productFilter, setFilterProduct] = useState(false);
    const [selectCatalog, setSelectCatalog] = useState('all');


    async function getAdvEf(clear = true, searchs = "") {

        if (clear) {
            setProduct([]);
        }
        let productList = [...((await getAdv("?catalog_id=all&status=open&city_id=" + state_in.city_select + "&search=" + searchs + "&sort=" + page)).data)];


        let categoryGet = [...((await getCategory("")).data)];
        setCategory(categoryGet);

        if (rait) {
            productList = productList.filter((pro) => {
                return pro?.user_id?.rait >= rait.value;
            });
        }


        setTimeout(() => {

            setProduct([...productList]);
        }, 100);
    }

    if (state_in.city_select != citydId) {
        setCityId(state_in.city_select);
        getAdvEf();
    }

    useEffect(() => {
        getAdvEf();
    }, []);
    return (
        <Layout>
            <div className="container-first">
                <div className="container">

                </div>

                <a onClick={() => {
                    State({city_open_adv: true});
                    // alert('Нет доступа')
                }} className={'container-btn-select'}>

                </a>
            </div>

            <div className="container-first container-first-mobail">


                <a onClick={() => {
                    State({city_open_adv: true});
                    // alert('Нет доступа')
                }} className={'container-btn-select'}>

                </a>
            </div>
            <div className="container">


                <div className='filter-main'>

                    <div className="filter-main__name text text-s40">
                        Трендовые запросы
                    </div>

                    <div className={'flex-box-filter'}>
                        <div onClick={() => {

                        }} className="filter-main__img">
                            {/*<div onClick={() => {*/}
                            {/*    State({city_open: true});*/}
                            {/*}} className={'img-icon'} src={require("../../assets/images/btn.svg")}/>*/}
                            <img className={'img-point'} src={require("../../assets/images/filter_icon.png")}/>
                            <p className={'text text-bold text-s20'}>
                                Фильтр
                            </p>
                        </div>
                        <div onClick={() => {
                            State({city_open: true});
                        }} className="filter-main__img">
                            {/*<div onClick={() => {*/}
                            {/*    State({city_open: true});*/}
                            {/*}} className={'img-icon'} src={require("../../assets/images/btn.svg")}/>*/}
                            <img className={'img-point'} src={require("../../assets/images/pointUser.png")}/>
                            <p className={'text text-bold text-s20'}>
                                {state_in.city_select}
                            </p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="container">
                <div className="catalog">


                    <div className="catalog_list-list">
                        <div className="catalog_list" style={{justifyContent: "flex-start"}}>
                            <a data-un="true" onClick={async () => {
                                setSelectCatalog('all');
                                let productList = ((await getAdv("?catalog_id=all&status=open&city_id=" + state_in.city_select)).data)
                                setProduct([]);
                                setTimeout(() => {
                                    setProduct([...productList]);
                                }, 100);
                            }} className={"btn btn-catalog  text text-s16 " + (selectCatalog == "all" && 'active')}>
                                Все категории
                            </a>

                            {
                                category.filter((item, index) => {
                                    return index < limit;
                                }).map((ites) => {
                                    return (
                                        <a onClick={async () => {
                                            setSelectCatalog(ites.id);
                                            let productList = ((await getAdv("?catalog_id=" + ites.id + "&status=open&city_id=" + state_in.city_select)).data)
                                            setProduct([]);
                                            setTimeout(() => {
                                                setProduct([...productList]);
                                            }, 100);
                                        }} data-un="true"
                                           className={"btn btn-catalog  text text-s16 " + (ites.id == selectCatalog && 'active')}>
                                            {ites.name}
                                        </a>
                                    )
                                })
                            }
                            <a onClick={async () => {
                                if (limit == 10) {
                                    setLimit(1000);
                                } else {
                                    setLimit(10);
                                }
                            }} data-un="true"
                               style={{
                                   backgroundColor: "#00A0E4",
                                   width: "auto",
                                   minWidth: "auto",
                                   padding: "0.75rem 1.5rem",
                                   color: "#fff"
                               }}
                               className={"btn btn-catalog  text text-s16 "}>
                                {
                                    limit == 10 ? ' + Еще' : 'Скрыть'
                                }
                            </a>

                        </div>
                    </div>
                    <div className="catalog_main">
                        {
                            product.map((item) => {
                                return (
                                    <Adv itemOpen={item}/>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );
});
export default MainScreen;
