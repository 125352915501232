import './style.scss'

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";
import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import SelectSearch from 'react-select';
import {getAdv, getChat} from "../../services";
import Adv from "../../components/Adv";

import One from "../../assets/images/1.png";
import Two from "../../assets/images/2.png";
import TreeImg from "../../assets/images/3.png";

const MainScreen = (() => {


    return (<Layout>
        <div className="container about">

            <span style={{
                fontSize: 20, padding: 20, width: '95%', margin: 'auto', display: 'flex', flexDirection: "column"
            }}>
                <span style={{fontWeight:"bold"}}>
                    Оферта
                </span>
<br/>
                Настоящая публичная оферта является официальным предложением (публичной офертой) Товарищество с ограниченной ответственностью «GM inc» (в дальнейшем "Исполнитель") для любого физического лица либо юридического лица (далее - "Заказчик"), которое примет настоящее предложение на указанных ниже условиях. Условия данной оферты являются существенными.<br/>
                В соответствие с пунктом 5 статьи 395 Гражданского кодекса Республики Казахстан, в случае принятия изложенных ниже условий и оплаты услуги, физическое либо юридическое лицо, производящее акцепт этой оферты, становится Заказчиком и акцепт оферты равносилен заключению договора на условиях, изложенных в оферте.<br/>
                Моментом полного и безоговорочного принятия Заказчиком предложения Исполнителя заключить договор публичной оферты (акцептом оферты) считается факт авторизации/регистрации в Мобильном приложении «Disconty», путем введения четырехзначного код-пароля.<br/>
                Начиная использовать Мобильное приложение «Disconty» в любой форме и в любом объеме Вы:<br/>
                - подтверждаете, что Вы в полном объеме ознакомились с содержанием Договором публичной оферты;<br/>
                -  выражаете свое полное и безусловное согласие с условиями, изложенными в настоящем Договоре публичной оферты, без каких-либо оговорок и изъятий;<br/>
                - принимаете на себя обязательство исполнять Договор публичной оферты, нести ответственность за неисполнение изложенных в нем требований и условий;<br/>
                - соглашаетесь с тем, что Договор публичной оферты может быть изменен Исполнителем без каких-либо уведомлений со Стороны Исполнителя, и обязуетесь самостоятельно отслеживать соответствующие изменения. Новая редакция Договора публичной оферты вступает в силу с момента ее размещения в Мобильном приложении «Disconty»;<br/>
                - соглашаетесь с получением посредством Мобильного приложения push-уведомлений, связанных с услугами Исполнителя, ее партнеров, а также содержание рекламные материалы;<br/>
                - соглашается с тем, что Мобильное приложение «Disconty» постоянно совершенствуется, в связи с чем возможны технические проблемы в его надлежащем функционировании;<br/>
                - Исполнитель не несет ответственность за Устройство, используемое Заказчиком при работе с Мобильным приложением «Disconty».<br/>
                В случае несогласия с изложенными в настоящем разделе условиями незамедлительно прекратите использовать сервисы Мобильного приложения «Disconty».<br/>
                Термины и определения<br/>
                1.1. Исполнитель — Товарищество с ограниченной ответственностью «Disconty»<br/>
                Юридический адрес: Республика Казахстан, 050000, город Алматы, Алмалинский район,  улица Гагарина 12, 168,<br/>
                1.2. Заказчик — любое физическое или юридическое лицо, акцептировавшее публичную оферту на условиях настоящей оферты и за которым зарегистрировано, в установленном законом Республики Казахстан Устройство.<br/>
                1.3. Заведение – места общественного питания, включая, но не ограничиваясь, рестораны, кофейни, бары, гастро-бары, ночные клубы и т.д., предоставляющие информацию о себе в Мобильном приложении «Disconty», актуальную информацию по столам для бронирования, принимающие оплату за Услуги, обеспечивающие предоставление услуг заведения по оплаченным Услугам.<br/>
                1.4. Мобильное приложение «Disconty» – программа, предназначенная для  организации взаимодействия Заказчика с Заведением, доступ к которому осуществляется через Мобильное приложение к Устройству. Мобильное приложение «Disconty» устанавливается Заказчиком самостоятельно.<br/>
                1.5. Устройство – мобильное техническое устройство (смартфон, планшет или другое устройство), имеющее доступ к сети Интернет.<br/>
                1.6. Услуга – возможность бронирования столов в Заведениях, представленных в Мобильном приложении, возможность просмотра карточек и меню Заведений, поиск по Заведениям, возможность редактирования профиля, возможность осуществления онлайн-оплаты.<br/>
                1.7. Акцепт Оферты - полное и безоговорочное принятие оферты. Моментом полного и безоговорочного принятия Заказчиком предложения Исполнителя заключить договор публичной оферты (акцептом оферты) считается факт регистрации в Мобильном приложении «Disconty»<br/>
                2. Правила пользования и регистрация в Мобильном приложении «Disconty»<br/>
                2.1. Заказчик не приобретает каких-либо прав на Мобильное приложение «Disconty» при установке и дальнейшем использовании<br/>
                2.2. Заказчик не имеет права:<br/>
                – передавать свои права/ обязанности на использование Мобильного приложения «Disconty» третьим лицам, в противном случае, Исполнитель не несет ответственности за любые последствия использования Мобильного приложения третьими лицами;<br/>
                –копировать, использовать Мобильное приложение для создания и публикации программ или баз данных аналогичных Мобильному приложению,  совершать иные действия, нарушающие права Исполнителя;<br/>
                – использовать Мобильное приложение «Disconty» в составе иных услуг, для предложения услуг третьим лицам или в иных коммерческих целях, а также использовать Мобильное приложение в целях причинения вреда имущественного, репутационного, имиджевого и иного характера Заведениям;<br/>
                – использовать Мобильное приложение для нарушения прав третьих лиц, а также целей, противоречащих действующему законодательству Республики Казахстан.<br/>
<br/>
                2.3. Исполнитель вправе приостанавливать либо полностью прекратить доступ (заблокировать) Заказчика к Мобильному приложению «Disconty» в случае нарушения последним условий настоящего Договора.<br/>
                2.4. Заказчик устанавливает на своем устройстве Мобильное приложение при помощи «App Store» или «Google Play».<br/>
                2.5. При первом использовании Мобильного Приложения Заказчик проходит:<br/>
                2.5.1. авторизацию, после чего получает возможность просмотра карточек и меню заведений, возможность поиска по заведениям, либо<br/>
                2.5.2. регистрацию, после чего получает возможность бронирования столов в заведениях, представленных в Мобильном приложении, возможность просмотра карточек и меню заведений, поиск по заведениям, возможность редактирования профиля, возможность осуществления онлайн-оплаты.<br/>
                2.6. Заказчик вправе зарегистрироваться в Мобильном приложении только 1(один) раз.<br/>
                2.7. Исполнитель не несет ответственности за достоверность, точность, полноту и правильность информации, предоставляемой Заказчиком при регистрации.<br/>
                2.8. Заказчик, зарегистрировавшийся в Мобильном приложении, получает индивидуальную идентификацию путем предоставления логина и пароля. Индивидуальная идентификация Заказчику позволяет избежать несанкционированных действий третьих лиц от имени Заказчика. Передача Заказчиком логина и пароля третьим лицам запрещена.<br/>
                2.9. Заказчик самостоятельно несёт ответственность за все возможные негативные последствия, в случае передачи логина и пароля третьим лицам.<br/>
                2.10. Пройдя регистрацию в Мобильном приложении «Disconty» Заказчик выражает свое согласие с условиями Договора, обязуется их выполнять и разрешает Исполнителю, а также по его поручению третьим лицам осуществлять обработку всех персональных данных, указанных в Мобильном приложении «Disconty», включая их сбор систематизацию, накопление, хранение, уточнение и иное использование, обезличивание и уничтожение персональных данных в рамках и целях реализации Договора и связанных с возможностью предоставления информации об услугах, бонусах и специальных услугах для пользователей Мобильного приложения.<br/>
                2.11. Заказчик, регистрируясь в Мобильном приложении, соглашается на получение от Исполнителя сообщений на свое Устройство. Содержание сообщений непосредственно связано с информацией о Мобильном приложении «Disconty», информацией о Заведениях и их Услуг в рамках Договора.<br/>
                2.12. Заказчик настоящим уведомлен и соглашается со следующим:<br/>
                Использование Мобильного приложения «Disconty», передача данных, совершение иных действий, возможны только при наличии доступа к сети Интернет. Для бесперебойной работы Мобильного приложения «Disconty» Заказчику необходимо самостоятельно обеспечить надлежащее качество доступа к сети Интернет на своем Устройстве.<br/>
                3. Предмет договора.<br/>
                3.1. Исполнитель размещает перечень Услуг в Мобильном приложении, а Заказчик принимает и оплачивает Услуги на условиях настоящего Договора.<br/>
                3.2. Мобильное приложение «Disconty» является интеллектуальной собственностью Исполнителя, правовая охрана которой устанавливается Гражданским Кодексом Республики Казахстан, международными договорами, ратифицированными Республикой Казахстан. <br/>
                3.4. Правообладатель Мобильного приложения «Disconty» вправе выпускать новые релизы и версии Мобильного приложения, которые вступают в силу с момента.<br/>
                4. Стоимость Услуг и порядок их оплаты.<br/>
                4.1. Стоимость услуги в Мобильном приложении «Disconty» по бронированию столов устанавливается и взымается исключительно на усмотрение и на расчетный счет Заведения.<br/>
                4.2. Заказчик производит оплату в тенге в размере 100% (ста процентов) стоимости приобретаемых Услуг посредством банковских карт (Visa, MasterCard) или иными доступными способами, указанных в Мобильном приложении «Disconty». Выбор и использование способа оплаты за Услуги производится Заказчиком по собственному усмотрению и без какой-либо ответственности Исполнителя. Безопасность, конфиденциальность, а также иные условия использования выбранных Заказчиком способов оплаты выходят за рамки настоящего Договора и регулируются взаимоотношениями между Заказчиком и собственниками заведений.<br/>
                4.3. Оплата по настоящему Договору считается совершенной Заказчиком в момент получения от соответствующей платежной системы верифицированной информации о поступлении платежа за Услуги.<br/>
                4.4. Заказчик самостоятельно несет ответственность за правильность производимых им платежей. Заведение самостоятельно несет все банковские комиссионные расходы по оплате Услуг. Исполнитель предоставляет исключительно транзитный счет для перевода денежных средств от Заказчика к Заведению.<br/>
                5. Оформление и получение Услуги<br/>
                5.1. При первом использовании Мобильного приложения «Disconty» Заказчик проходит авторизацию или регистрацию, после чего получает возможность получения соответствующих Услуг.<br/>
                5.2. Незарегистрированный Заказчик имеет право на осуществление следующих действий:<br/>
                •	Регистрироваться<br/>
                •	Просматривать карточки и меню заведений<br/>
                •	Осуществлять поиск по заведениям<br/>
                5.3. Зарегистрированный Заказчик имеет право на осуществление следующих действий:<br/>
                •	Авторизоваться<br/>
                •	Бронировать столы в заведениях<br/>
                •	Просматривать карточки и меню заведений<br/>
                •	Осуществлять поиск по заведениям<br/>
                •	Редактировать профиль<br/>
                •	Осуществлять онлайн-оплату<br/>
                6. Права и обязанности сторон<br/>
                6.1. Исполнитель обязуется:<br/>
                6.1.1. Строго соблюдать условия настоящего Договора.<br/>
                6.1.2. Обрабатывать персональные данные Заказчика и обеспечивать их конфиденциальность в порядке, установленном действующим законодательством.<br/>
                6.2. Исполнитель имеет право:<br/>
                6.2.1. Без согласования с Заказчиком, передавать свои права и обязанности по исполнению Договора третьим лицам.<br/>
                6.3. Заказчик обязуется:<br/>
                6.3.1. Строго соблюдать условия настоящего Договора.<br/>
                6.3.2. Предоставить Исполнителю полную и достоверную информацию, необходимую для исполнения настоящего договора.<br/>
                7. Форс-мажор<br/>
                7.1. Любая из Сторон освобождается от ответственности за полное или частичное неисполнение своих обязательств по настоящему Договору, если это неисполнение было вызвано обстоятельствами непреодолимой силы, возникшими после заключения настоящего Договора.<br/>
                «Обстоятельства Непреодолимой Силы» означают чрезвычайные события или обстоятельства, которые та или иная Сторона не могла предвидеть или предотвратить доступными ей средствами.<br/>
                Такие чрезвычайные события или обстоятельства включают в себя, в частности: забастовки, наводнения, пожары, землетрясения и иные стихийные бедствия, войны, военные действия, действия государственных органов, а также любые иные обстоятельства, выходящие за пределы разумного контроля любой из Сторон.<br/>
                Изменения действующего законодательства или нормативных актов, прямо или косвенно влияющие на какую-либо из Сторон, не рассматриваются как Обстоятельства непреодолимой силы, однако, в случае внесения таких изменений, которые не позволяют любой из Сторон исполнить какие-либо из ее обязательств по настоящему Договору, Стороны обязаны незамедлительно принять решение относительно порядка работы по устранению этой проблемы с тем, чтобы обеспечить Сторонам продолжение исполнения настоящего Договора.<br/>
                8. Ответственность сторон<br/>
                8.1. За неисполнение или ненадлежащее исполнение условий настоящего Договора Стороны несут ответственность в соответствии с законодательством Республики Казахстан.<br/>
                8.2. Исполнитель не несет ответственности за ущерб, полученный Заказчиком  в результате  использования или не использования Мобильного приложения «Disconty». Исполнитель не возмещает Заказчику упущенную выгоду и/или моральный вред.<br/>
                8.3. Мобильное приложение «Disconty» предоставляется на условиях «как есть». Исполнитель не предоставляет никаких гарантий в отношении соответствия Мобильного приложения  конкретным целям и ожиданиям Заказчика, а также не предоставляет никаких иных гарантий, прямо не указанных в настоящем Договоре включая оказание услуг Заведениями. Ни при каких условиях Исполнитель не несет ответственности перед Заказчиком за убытки, включая любые прямые, косвенные, умышленные, случайные или последующие убытки любого характера проистекающие из настоящего Договора  или из использования или невозможности использования Мобильного приложения «Disconty» (включая, но, не ограничиваясь, убытками, возникшими в результате потери деловой репутации, прекращения работы, технического сбоя, аварии или неисправности или любых коммерческих убытков, издержек или потерь, а равно упущенной выгоды или неосновательного обогащения) даже если Исполнителю было известно или должно было быть известно о возможности такого ущерба или Исполнитель был предупрежден о возможности такого ущерба.<br/>
                8.4. Исполнитель предпринимает все разумные меры и осуществляет любые целесообразные действия, направленные на поддержание работоспособности Мобильного приложения «Disconty». Исполнителем не производится гарантийное обслуживание Мобильного приложения в любом его виде на Устройстве Заказчика.<br/>
                8.5. Исполнитель  не несет ответственность за передачу Исполнителем  информации третьим лицам с помощью Мобильного приложения «Disconty», а также, в случае если информация из Мобильного приложения «Disconty» стала доступна третьим лицам вследствие их несанкционированного доступа к Устройству или действий вирусного или вредоносного программного обеспечения на Устройстве.<br/>
                8.6. Исполнитель не несет ответственности за невозможность использования Мобильного приложения «Disconty»  по независящим от него обстоятельствам.<br/>
                8.7. Настоящий Договор не дает Заказчику никаких прав на использование объектов интеллектуальной собственности, включая товарные знаки и знаки обслуживания Исполнителя или Заведений.<br/>
                8.8. Исполнитель не контролирует содержание контента, размещенного, передаваемого, используемого в Мобильном приложении Заведениями и Заказчиками и не инициирует передачу информации, размещенной Заведением и/или Заказчиком в Мобильном приложении, и не несет за них ответственность. Заведение и Заказчик несут самостоятельную ответственность за любой контент или иную информацию, которую он/они размещают, передают и/или используют в Мобильном приложении или с его помощью. В случае предъявления претензий о нарушении прав третьих лиц, в связи с размещением, использованием, передачей информации и/или при возникновении соответствующих рисков, Исполнитель имеет право удалить соответствующий контент и/или информацию.<br/>
                9. Прочие условия<br/>
                9.1. Все споры или разногласия, возникающие между Сторонами по Договору или в связи с ним, разрешаются путем переговоров между Сторонами.<br/>
                9.2. Стороны пришли к соглашению, что в случае невозможности разрешения разногласий путем переговоров они подлежат рассмотрению в соответствующем суде судебной системы Республики Казахстан по месту нахождения Исполнителя (договорная подсудность).<br/>
                9.3. По вопросам, не урегулированным Договором, подлежат применению законы и иные правовые акты Республики Казахстан.<br/>
                9.4. Ссылки на слово или термин в Договоре в единственном числе включают в себя ссылки на это слово или термин во множественном числе. Ссылки на слово или термин во множественном числе включают в себя ссылки на это слово или термин в единственном числе. Данное правило применимо, если из текста Договора не вытекает иное.<br/>
                9.5. Настоящий Договор считается заключенным с любым физическим или юридическим лицом с момента полного и безоговорочного акцепта таким лицом условий настоящего Договора. С момента полного и безоговорочного акцепта условий настоящего Договора указанное лицо становится Стороной настоящего Договора.<br/>
                9.6. Стороны настоящим соглашаются, что в целях регулирования обмена конфиденциальной информацией между ними в течение всего срока действия настоящего Договора каждая Сторона будет использовать ее исключительно в целях, указанных в настоящем Договоре и в связи с ним (далее – Конфиденциальная информация), включая, но не ограничиваясь: номер сотового телефона, персональные данные, информация касающаяся получения услуг и т.д.). Для целей настоящего Договора Конфиденциальная информация должна включать всю информацию, неизвестную или недоступную для неопределенного круга лиц и имеющую отношение к раскрывающей Стороны, наименованию деловых партнеров или потенциальных деловых партнеров раскрывающей Стороны, условиям, указанным в настоящем Договоре и (или), а также любую иную информацию, классифицированную как конфиденциальная или служебная информация раскрывающей Стороны.  Без ограничения вышеизложенного, каждая Сторона соглашается с тем, что:<br/>
                - она будет хранить Конфиденциальную информацию в секретности, проявляя в отношении нее по крайней мере такую же осторожность, которую она проявляет по отношению к своей собственной Конфиденциальной информации, и не будет без предварительного письменного согласия раскрывающей Стороны раскрывать никакую ее часть никакому лицу, за исключением случаев, когда и в той мере, в которой это требуется в силу закона (в этом случае раскрывающей Стороне должно быть незамедлительно предоставлено письменное уведомление о таком раскрытии);<br/>
                - обязательства о сохранении конфиденциальности, изложенные в настоящем пункте, не должны применяться с целью предотвращения раскрытия Конфиденциальной информации одной из Сторон своим аффилированным лицам или должностным лицам, директорам, сотрудникам, кредиторам или потенциальным кредиторам, агентам, консультантам и представителям такой Стороны или ее аффилированных лиц по принципу служебной необходимости с сохранением конфиденциальности такой Конфиденциальной информации, при условии, что такие лица соглашаются соблюдать требования сохранения конфиденциальности согласно настоящему пункту;<br/>
                - по письменному требованию раскрывающей Стороны другие Стороны должны уничтожить любые выписки, заметки и расчеты, содержащие любую часть такой Конфиденциальной информации;<br/>
                - положения настоящего пункта будут оставаться в силе в течение пяти (пять) лет после истечения срока или преждевременного расторжения настоящего Договора;<br/>
                - Стороны согласуют содержание любого публичного заявления или сообщения в отношении настоящего Договора до того, как оно будет сделано если иное прямо не предусмотрено Договором.<br/>
<br/>
                 10. Реквизиты Исполнителя:<br/>
                ТОО «Disconty»<br/>
                БИН #############<br/>
                Юридический адрес: Республика Казахстан,<br/>
                 050000, город Алматы, Гагарина 12, 168.<br/>
<br/>
<br/>
                Подписывая данное соглашение Заказчик соглашается и принимает все условия настоящей оферты
                </span>
        </div>
    </Layout>);
});
export default MainScreen;
