import React, {useState} from 'react';
import './style.scss';
import {useSelector} from "react-redux";
import StateSlices from "../../../../redux/slices/State";
import {NotificationManager} from "react-notifications";
import {deleteDBAdv, deleteDBAdvProduct, getAdvOpen, updateDB} from "../../../../services";
import Modal from "react-modal";

const More = (({
                   clasName,
                   typeDiscond,
                   priceAllDiscond,
                   removeCh,
                   codeType = 0,
                   item,
                   itemOpen,
                   update_skidka,
                   discond,
                   discondMinus,
                   dola,
                   th = true,
                   add = true,
                   lable,
                   name,
                   onClick,
                   disable
               }) => {

    const state_in = useSelector(StateSlices);

    const [openForm, setOpen] = useState(false);

    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productCount, setProductCount] = useState('');
    const [productComment, setComment] = useState('');
    const [addProduct, setAddProduct] = useState(null);


    function numberWithSpaces(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
        return x;
    }

    if (window.innerWidth < 760 && add != true) {
        th = true;
    }

    return (
        <div className="adv__main adv__main--fix">

            <Modal
                isOpen={addProduct}
                onRequestClose={() => {
                    setAddProduct(false);
                }}
                contentLabel="Example Modal"
                className="model-add-adb-product"
            >
                <form method={'post'} onSubmit={async (event) => {
                    event.preventDefault();

                    await updateDB({
                        'save': {
                            name: productName,
                            price: productPrice,
                            count: productCount,
                            comment: productComment,
                            advertisement_id: itemOpen.id,
                            user_id: state_in.user.id,
                        },
                        'id': 0,
                        'model_name': 'AdvertisementProduct',
                    });

                    setAddProduct(false);
                    setComment('');
                    setProductCount('');
                    setProductPrice('');
                    setProductName('');

                    if (removeCh) {
                        removeCh();
                    }
                    return false;
                }} className="model-adv">

                    <div className="model-adv__head model-adv__head-back">
                        <p className={'text text-s30'}>
                            Добавить товар
                        </p>
                    </div>
                    <div className="input-add-product">
                        <div className="input-add-product__label">
                            <p className={'text text-s24'}>
                                Наименование
                            </p>
                        </div>
                        <input onChange={(event) => {
                            setProductName(event.target.value);
                        }} value={productName} required={true} className={'text input-add-product__input text-s24'}/>
                    </div>
                    <div className="input-add-product">
                        <div className="input-add-product__label">
                            <p className={'text text-s24'}>
                                Стоимость
                            </p>
                        </div>
                        <input onChange={(event) => {
                            setProductPrice(event.target.value);
                        }} value={productPrice} required={true} className={'text input-add-product__input text-s24'}/>
                    </div>


                    <div className="input-add-product">
                        <div className="input-add-product__label">
                            <p className={'text text-s24'}>
                                Количество
                            </p>
                        </div>
                        <input onChange={(event) => {
                            if (parseInt(event.target.value) > 1) {
                                setProductCount(1);
                            } else {
                                setProductCount(event.target.value);
                            }
                        }} value={productCount} required={true} className={'text input-add-product__input text-s24'}/>
                    </div>

                    <div className="input-add-product">
                        <div className="input-add-product__label">
                            <p className={'text text-s24'}>
                                Комментарии
                            </p>
                        </div>
                        <textarea onChange={(event) => {
                            setComment(event.target.value);
                        }} required={true} value={productComment} style={{minHeight: '5rem'}} cols={10}
                                  className={'text input-add-product__input text-s24'}></textarea>
                    </div>

                    <div className="model-adv_create">
                        <a href="javascript:void(0)" onClick={() => {
                            setAddProduct(false);
                            setComment('');
                            setProductCount('');
                            setProductPrice('');
                            setProductName('');
                        }} className="btn clear-modal btn-link text text-s24">
                            Назад
                        </a>
                        <button type={'submit'} className="btn create-adb btn-link  text text-s24">
                            Добавить
                        </button>
                    </div>
                </form>
            </Modal>


            {/*<div className="adv__user adv__user--fix ">*/}
            <div className="adv__catalog adv__catalog-sm-forst order-first">
                {
                    th && (
                        <div className="adv-th">
                            <p className={'text text-w700 text-s15'}>
                                Товар
                            </p>
                        </div>
                    )
                }
                <div className="adv-td">
                    {
                        !add && (
                            <>
                                <div className="">
                                    <a title={item.name} style={{minWidth: "11rem"}}
                                       className={'text text-s18'}>
                                      <span className={''}>
                                            {item.name.substr(0, 20)}
                                          {item.name.length > 20 && '...'}
                                      </span>
                                    </a>
                                </div>
                            </>
                        )
                    }
                    {
                        add && (
                            <>
                                {
                                    add && (
                                        <>
                                            <a href="javascript:void(0)" onClick={async () => {
                                                if (!state_in.token) {
                                                    NotificationManager.info('Пройдите авторизацию', 'Error');
                                                } else {
                                                    let user_info = (await getAdvOpen(itemOpen.id)).data;
                                                    let is_join_d = (user_info.user_list.filter((itemsa) => {
                                                        return itemsa.user_id.id == state_in.user.id && itemsa.status == 'done';
                                                    })).length > 0;


                                                    if (is_join_d || state_in.user.id == user_info.user_id.id) {
                                                        setAddProduct(true);
                                                    } else {
                                                        NotificationManager.error('Вы не состоите в группе', '');
                                                    }
                                                }
                                            }} className={'text text-w700 text-s15'}>
                                                Добавить
                                            </a>
                                            <p className={'text text-s12'}>
                                                Рейтинг: -
                                            </p>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
            <div className="adv__group adv__catalog-sm-forst sm-price" style={{width: "11%"}}>
                {
                    th && (
                        <div className="adv-th" >
                            <p className={'text text-w700 text-s15'}>
                                Без скидки
                            </p>
                        </div>
                    )
                }
                <div className="adv-td">
                    {
                        !add && (
                            <>
                                <p style={{color: "#FF0000"}} className={'text text-w700 text-s20'}>
                                    {numberWithSpaces(('' + (item.price)).includes('.') ? parseFloat(item.price).toFixed(2) : item.price)} тг
                                </p>
                            </>
                        )
                    }
                    {
                        add && (
                            <>
                                <p style={{color: "#FF0000"}}
                                   className={'text text-w700 text-s20'}>
                                    -
                                </p>
                            </>
                        )
                    }
                </div>
            </div>


            <div className="adv__group sm-price">
                {
                    th && (
                        <div className="adv-th">
                            <p className={'text text-w700 text-s15'}>
                                Скидка
                            </p>
                        </div>
                    )
                }
                <div className="adv-td">
                    <div className="">
                        {
                            !add && ((codeType != 5 || itemOpen?.user_id?.id != state_in.user.id)) && (
                                <>
                                    {
                                        codeType != 5 && (
                                            <div className={' text text-s15'}>
                                                {parseInt(priceAllDiscond * (parseFloat(dola) / 100))}
                                            </div>
                                        )
                                    }

                                    {
                                        codeType == 5 && (
                                            <div className={'  text text-s15'}>
                                                {numberWithSpaces(discondMinus)}
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                        {
                            !add && codeType == 5 && itemOpen?.user_id?.id == state_in.user.id && (
                                <>
                                    <input type={'number'}
                                           onChange={(evens) => {
                                               if (update_skidka) {
                                                   update_skidka(evens.target.value);
                                               }
                                               //
                                           }}
                                           value={item.discond}
                                           className={'adv-input-name adv-input-name-border text text-s15'}/>

                                </>
                            )
                        }
                        {
                            add && (
                                <>
                                    <div className={' text text-s15'}>
                                        -
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="adv__group sm-price" style={{width: "8%"}}>
                {
                    th && (
                        <div className="adv-th" style={{paddingLeft: "1rem"}}>
                            <p className={'text text-w700 text-s15'}>
                                К оплате
                            </p>
                        </div>
                    )
                }
                <div className="adv-td" style={{paddingLeft: "1rem"}}>
                    <p style={{color: "#127634"}} className={'text text-w700 text-s16'}>
                        {
                            !add && codeType == 5 && (
                                <>
                                    {numberWithSpaces(parseInt(item.price) - discondMinus)}
                                </>
                            )
                        }
                        {
                            !add && codeType != 5 && (
                                <>
                                    {numberWithSpaces(parseInt(item.price - (priceAllDiscond * (parseFloat(dola) / 100))))}
                                </>
                            )
                        }

                        {
                            add && (
                                <>
                                    -
                                </>
                            )
                        }
                    </p>
                </div>
            </div>



        </div>
    )
});


export default More;
