const INITIAL_STATE = {
    'favorite': []
};

const FavoriteReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_FAVORITE':
            if (state.favorite.includes(action.payload)) {
                return state;
            }
            return {
                ...state,
                ...{favorite: [...state.favorite, action.payload]}
            };
        case 'DELETE_FAVORITE':
            return {
                    ...state,
                    ...{
                        favorite: state.favorite.filter((item) => {
                            return action.payload != item
                        })
                    }
            };
        default:
            return state;
    }
};

export default FavoriteReducer;
