import './style.scss'

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import AdvItem from "../../components/Adv/components/AdvItem";
import {deleteDBAdvUser, getAdvOpen, getChat, getPayBox, updateDB} from "../../services";
import {NotificationManager} from "react-notifications";
import StarFull from "../../assets/images/star_full.png";
import StarEmpty from "../../assets/images/star_empty.png";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import AdvItemCustom from "../../components/Adv/components/AdvItemCustom";
import Modal from "react-modal";

const {REACT_APP_ASSETS_PATH} = process.env;

const MainScreen = ((id) => {

    const params = useParams();


    const state_in = useSelector(StateSlices);

    const [openModal, setOpenModal] = useState(false);
    const [timerClear, setTimerClear] = useState(false);
    const [openRew, setOpenRew] = useState(false);
    const [openChat, setOpenChat] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);

    const [titleName, setTitleName] = useState('оставить отзыв лидеру запроса');
    const [rewName, setRewName] = useState('');

    const [starNum, setStarNum] = useState(1);
    const [userRow, setUserRow] = useState(0);

    const [reset, setReset] = useState(false);
    const [timerSave, setTimer] = useState(false);

    const [resetMessage, setResetMessage] = useState(false);


    const [chBtn, setChBtn] = useState(false);

    const [itemOpen, setItemOpen] = useState({});
    const [userListCh, setUserListCh] = useState([]);


    const [message_text, setMessage] = useState('');

    const [reset2, setReset2] = useState(false);
    // const [item, setItem] = useState(itemOpen);
    // let item = itemOpen;
    // const setItem = (datasa) => {
    //     item = datasa;
    // }

    const [item, setIIIIs] = useState(itemOpen);


    async function getAdvBox() {
        const getInfoData = (await getAdvOpen(params.id)).data;

        let userChList = (getInfoData?.user_ch_list ? getInfoData?.user_ch_list : '').split(',');

        setUserListCh(userChList);

        setIIIIs(getInfoData);
        setItemOpen(getInfoData);

    }


    useEffect(() => {

        setTimeout(() => {
            if (!load) {
                getAdvBox();
            }

            setReset2(!reset2);

        }, 2500);

    }, [reset2])


    useEffect(() => {
        getAdvBox();
    }, [params.id])

    useEffect(() => {
        getAdvBox();
    }, [params.id])


    // let item = itemOpen;
    const setItem = (datasa) => {
        setIIIIs(datasa);
    }

    async function removeCh(data) {
        setIIIIs((await getAdvOpen(itemOpen.id)).data);
    }


    async function updateAdv(data) {
        setIIIIs((await getAdvOpen(itemOpen.id)).data);
    }

    function numberWithSpaces(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
        return x;
    }


    const [timeOut, setTimeOutSav] = useState(null);
    const [priceMin, setPriceMin] = useState(null);
    const [priceMax, setPriceMax] = useState(null);
    const [load, setLoad] = useState(null);


    const [timerMessage, setTimerMessage] = useState(null);


    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productCount, setProductCount] = useState('');
    const [productComment, setComment] = useState('');
    const [messageChat, setMessageChat] = useState([]);


    const listType = [
        ['На всё', 0],
        ['На самый дешёвый', -1],
        ['На самый дорогой', -2],
        ['На второй', 2],
        ['На третий', 3],
        ['На четвертый', 4],
        ['Выставить вручную', 5],
        ['Выбрать позже', 6],
    ];


    const updateDbSend = async (json) => {
        await updateDB({
            'save': json,
            'id': item.id,
            'model_name': 'Advertisement',
        });

        await setItem((await getAdvOpen(item.id)).data);

    }

    const updateProduct = async (json, id) => {
        await updateDB({
            'save': json,
            'id': id,
            'model_name': 'AdvertisementProduct',
        });

        await setItem((await getAdvOpen(item.id)).data);

    }

    const updateDbSave = async (json) => {

        // if (timeOut) {
        //     clearTimeout(timeOut);
        // }

        let code_s = setTimeout(() => {
            updateDbSend(json);
        }, 1000);
        // setTimeOutSav(code_s)

    }


    const updateMessage = async () => {

        if (timeOut) {
            clearTimeout(timeOut);
        }

        setTimerMessage(
            setTimeout(async () => {
                if (openModal) {
                    await setMessageChat((await getChat()).data);
                }

                setResetMessage(!resetMessage)
            }, 1000)
        )

    }
    useEffect(() => {

        updateMessage();
    }, [resetMessage]);

    useEffect(() => {

    }, [reset]);


    if (!(item?.user_list)) {
        return (
            <p>

            </p>
        );
    }


    let is_join = (item.user_list.filter((user) => {
        return user.status == 'done';
    }).filter((itemsa) => {
        return itemsa.user_id.id == state_in.user.id;
    })).length > 0;


    let priceAllDiscond = 0
    if (item?.id && false) {
        priceAllDiscond = item.advertisement_product_list.reduce(function (previousValue, product, index) {
            const index_save = index;
            let discond = 0;
            let discondMinus = 0;
            let typeDiscond = '';


            let allPrice = item.advertisement_product_list.reduce(function (previousValue, currentValue) {
                if (priceMin == null) {
                    setPriceMin(parseInt(currentValue.price));
                } else if (priceMin > parseInt(currentValue.price)) {
                    setPriceMin(parseInt(currentValue.price));
                }
                if (priceMax == null) {
                    setPriceMax(parseInt(currentValue.price));
                } else if (priceMax < parseInt(currentValue.price)) {
                    setPriceMax(parseInt(currentValue.price));
                }

                return previousValue + parseInt(currentValue.price);
            }, 0)

            let typeDiscondControl = listType.filter((ds) => {
                return item.condition_discount == ds[0];
            });
            typeDiscondControl = typeDiscondControl[0][1];

            if (item?.type_discont == 'percent') {
                typeDiscond = "%";
                if (typeDiscondControl == 0) {
                    discond = item.count_discont;
                    discondMinus = product.price * (item.count_discont / 100);
                }
                if (typeDiscondControl == -1) {
                    if (product.price == priceMin) {
                        discond = item.count_discont;
                        discondMinus = product.price * (item.count_discont / 100);
                    }
                }
                if (typeDiscondControl == -2) {
                    if (product.price == priceMax) {
                        discond = item.count_discont;
                        discondMinus = product.price * (item.count_discont / 100);
                    }
                }
                if (typeDiscondControl == 2) {
                    if ((index + 1) % 2 == 0) {
                        discond = item.count_discont;
                        discondMinus = product.price * (item.count_discont / 100);
                    }
                }
                if (typeDiscondControl == 3) {
                    if ((index + 1) % 3 == 0) {
                        discond = item.count_discont;
                        discondMinus = product.price * (item.count_discont / 100);
                    }
                }
                if (typeDiscondControl == 4) {
                    if ((index + 1) % 4 == 0) {
                        discond = item.count_discont;
                        discondMinus = product.price * (item.count_discont / 100);
                    }
                }
                if (typeDiscondControl == 5) {
                    discond = parseInt(product.discond);
                    discondMinus = parseInt(product.price) * (discond / 100);
                }
            } else {
                let priceCurrent = 0;

                item.advertisement_product_list.map((pris, indexsas) => {
                    if (index_save > indexsas) {
                        if (typeDiscondControl == 0) {
                            priceCurrent += parseInt(pris.price);
                        }
                        if (typeDiscondControl == -1) {
                            if (pris.price == priceMin) {
                                priceCurrent += parseInt(pris.price);
                            }
                        }
                        if (typeDiscondControl == -2) {
                            if (pris.price == priceMax) {
                                priceCurrent += parseInt(pris.price);
                            }
                        }
                        if (typeDiscondControl == 2) {
                            if ((indexsas + 1) % 2 == 0) {
                                priceCurrent += parseInt(pris.price);
                            }
                        }
                        if (typeDiscondControl == 3) {
                            if ((indexsas + 1) % 3 == 0) {
                                priceCurrent += parseInt(pris.price);
                            }
                        }
                        if (typeDiscondControl == 4) {
                            if ((indexsas + 1) % 4 == 0) {
                                priceCurrent += parseInt(pris.price);
                            }
                        }

                    }
                });

                typeDiscond = 'тнг';
                if (typeDiscondControl == 0) {
                    discond = (product.price - item.count_discont) < 0 ? product.price : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                    discondMinus = discond;
                }
                if (typeDiscondControl == -1) {
                    if (parseInt(product.price) == priceMin) {
                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                        discondMinus = discond;
                    }
                }
                if (typeDiscondControl == -2) {
                    if (parseInt(product.price) == priceMax) {
                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                        discondMinus = discond;
                    }
                }
                if (typeDiscondControl == 2) {
                    if ((index + 1) % 2 == 0) {
                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                        discondMinus = discond;
                    }
                }
                if (typeDiscondControl == 3) {
                    if ((index + 1) % 3 == 0) {
                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                        discondMinus = discond;
                    }
                }
                if (typeDiscondControl == 4) {
                    if ((index + 1) % 4 == 0) {
                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                        discondMinus = discond;
                    }
                }
                if (typeDiscondControl == 5) {
                    discond = parseInt(product.discond);
                    discondMinus = discond;
                }
                // if (discondMinus > product.price) {
                //     discondMinus = product.price;
                // }
            }

            return previousValue + parseInt(parseInt(product.price) - parseInt(discondMinus));
        }, 0);

        priceAllDiscond = item.advertisement_product_list.reduce(function (previousValue, currentValue) {
            return previousValue + parseInt(currentValue.price);
        }, 0) - priceAllDiscond;
    }

    let imgOpen = [];

    if (item?.images) {
        imgOpen = item?.images.split(',');

    }

    if (imgOpen.length == 0 && item?.catalog_id_open?.images) {
        imgOpen = [item?.catalog_id_open?.images];
    }
    if (imgOpen.length > 0) {
        imgOpen = imgOpen[0];
    }


    let userListControl = [
        item.user_id,
        ...item.user_list.reduce((ass, user_id) => {
            return [...ass, user_id.user_id];
        }, [])
    ].reduce((ass, itepro) => {
        return {...ass, ...{[itepro.id]: itepro.name}};
    }, {});


    let userMap = item.advertisement_product_list.map((item) => {

        return {
            user_id: item.user_id.id,
            userName: userListControl[item.user_id.id],
            price: parseInt(item.price) * parseInt(item.count)
        }
    }).reduce((ass, iBoxPrice) => {

        return {...ass, ...{[iBoxPrice.user_id]: {...iBoxPrice, ...{price: (iBoxPrice.user_id in ass ? ass[iBoxPrice.user_id].price : 0) + (iBoxPrice.price)}}}}
    }, {});

    Object.entries(userListControl).forEach((controlItem) => {
        if (!(controlItem[0] in userMap)) {
            userMap[controlItem[0]] = {user_id: controlItem[0], userName: controlItem[1], price: 0};
        }
    });

    userMap = Object.entries(userMap).reduce((ass, iBoxPrice) => {
        return [...ass, iBoxPrice[1]]
    }, []);


    return (
        <Layout>

            <div className="container">
                <div className="ci-adv-new">
                    <div className="ci-adv-new__left">
                        <div className="ci-adv-new-name">

                            <div className="ci-adv-new-img-one">
                                <div className="ci-adv-new-img"
                                     style={{backgroundImage: "url(" + REACT_APP_ASSETS_PATH + imgOpen + ")"}}>

                                </div>
                            </div>
                            <div className="ci-adv-new-text">
                                <p className={'text text-bold text-s32'}>
                                    {item?.name}
                                </p>
                                <br/>
                                <p className={'text  text-s16'}>
                                    {item?.content}
                                </p>
                                <br/>
                                <p className={'text  text-s16'}>
                                    <span className={'text-bold'}>Условие скидки: </span>

                                    {
                                        item.condition_discount + ' ' +
                                        (item?.count_discont ? item?.count_discont : 0) + ' ' +
                                        (item.type_discont == 'percent' ? ' %' : ' тнг')
                                    }
                                </p>

                            </div>
                        </div>
                        <form method={'post'} onSubmit={async (item) => {

                        }} className="model-adv model-adv-single container">
                            <div className="model-adv__main">
                                <div className={'model-adv__main_body'}>
                                    <div className="ci-adv-new-product">
                                        <div className="adv__list">
                                            {
                                                item.advertisement_product_list.map((product, index) => {


                                                    const index_save = index;

                                                    let discond = 0;
                                                    let discondMinus = 0;
                                                    let typeDiscond = '';


                                                    let allPrice = item.advertisement_product_list.reduce(function (previousValue, currentValue) {
                                                        if (priceMin == null) {
                                                            setPriceMin(parseInt(currentValue.price));
                                                        } else if (priceMin > parseInt(currentValue.price)) {
                                                            setPriceMin(parseInt(currentValue.price));
                                                        }
                                                        if (priceMax == null) {
                                                            setPriceMax(parseInt(currentValue.price));
                                                        } else if (priceMax < parseInt(currentValue.price)) {
                                                            setPriceMax(parseInt(currentValue.price));
                                                        }

                                                        return previousValue + parseInt(currentValue.price);
                                                    }, 0)

                                                    let typeDiscondControl = listType.filter((ds) => {

                                                        return item.condition_discount == ds[0];
                                                    });

                                                    typeDiscondControl = typeDiscondControl[0][1];

                                                    if (item?.type_discont == 'percent') {
                                                        typeDiscond = "%";
                                                        if (typeDiscondControl == 0) {
                                                            discond = item.count_discont;
                                                            discondMinus = product.price * (item.count_discont / 100);
                                                        }
                                                        if (typeDiscondControl == -1) {
                                                            if (product.price == priceMin) {
                                                                discond = item.count_discont;
                                                                discondMinus = product.price * (item.count_discont / 100);
                                                            }
                                                        }
                                                        if (typeDiscondControl == -2) {
                                                            if (product.price == priceMax) {
                                                                discond = item.count_discont;
                                                                discondMinus = product.price * (item.count_discont / 100);
                                                            }
                                                        }
                                                        if (typeDiscondControl == 2) {
                                                            if ((index + 1) % 2 == 0) {
                                                                discond = item.count_discont;
                                                                discondMinus = product.price * (item.count_discont / 100);
                                                            }
                                                        }
                                                        if (typeDiscondControl == 3) {
                                                            if ((index + 1) % 3 == 0) {
                                                                discond = item.count_discont;
                                                                discondMinus = product.price * (item.count_discont / 100);
                                                            }
                                                        }
                                                        if (typeDiscondControl == 4) {
                                                            if ((index + 1) % 4 == 0) {
                                                                discond = item.count_discont;
                                                                discondMinus = product.price * (item.count_discont / 100);
                                                            }
                                                        }
                                                        if (typeDiscondControl == 5) {
                                                            discond = parseInt(product.discond);
                                                            discondMinus = parseInt(product.price) * (discond / 100);
                                                        }
                                                    } else {
                                                        let priceCurrent = 0;

                                                        item.advertisement_product_list.map((pris, indexsas) => {
                                                            if (index_save > indexsas) {
                                                                if (typeDiscondControl == 0) {
                                                                    priceCurrent += parseInt(pris.price);
                                                                }
                                                                if (typeDiscondControl == -1) {
                                                                    if (pris.price == priceMin) {
                                                                        priceCurrent += parseInt(pris.price);
                                                                    }
                                                                }
                                                                if (typeDiscondControl == -2) {
                                                                    if (pris.price == priceMax) {
                                                                        priceCurrent += parseInt(pris.price);
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 2) {
                                                                    if ((indexsas + 1) % 2 == 0) {
                                                                        priceCurrent += parseInt(pris.price);
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 3) {
                                                                    if ((indexsas + 1) % 3 == 0) {
                                                                        priceCurrent += parseInt(pris.price);
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 4) {
                                                                    if ((indexsas + 1) % 4 == 0) {
                                                                        priceCurrent += parseInt(pris.price);
                                                                    }
                                                                }

                                                            }
                                                        });

                                                        typeDiscond = 'тнг';
                                                        if (typeDiscondControl == 0) {
                                                            discond = (product.price - item.count_discont) < 0 ? product.price : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                            discondMinus = discond;
                                                        }
                                                        if (typeDiscondControl == -1) {
                                                            if (parseInt(product.price) == priceMin) {
                                                                discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                discondMinus = discond;
                                                            }
                                                        }
                                                        if (typeDiscondControl == -2) {
                                                            if (parseInt(product.price) == priceMax) {
                                                                discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                discondMinus = discond;
                                                            }
                                                        }
                                                        if (typeDiscondControl == 2) {
                                                            if ((index + 1) % 2 == 0) {
                                                                discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                discondMinus = discond;
                                                            }
                                                        }
                                                        if (typeDiscondControl == 3) {
                                                            if ((index + 1) % 3 == 0) {
                                                                discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                discondMinus = discond;
                                                            }
                                                        }
                                                        if (typeDiscondControl == 4) {
                                                            if ((index + 1) % 4 == 0) {
                                                                discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                discondMinus = discond;
                                                            }
                                                        }

                                                        if (typeDiscondControl == 5) {
                                                            discond = parseInt(product.discond);
                                                            discondMinus = discond;
                                                        }
                                                        // if (discondMinus > product.price) {
                                                        //     discondMinus = product.price;
                                                        // }
                                                    }

                                                    // if (typeDiscondControl == 5 || typeDiscondControl == 6) {
                                                    //     discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                    //     discondMinus = 0;
                                                    // }

                                                    let codeType = typeDiscondControl;

                                                    return (
                                                        <AdvItemCustom item={product}
                                                                       priceAllDiscond={priceAllDiscond}
                                                                       removeCh={removeCh}
                                                                       codeType={codeType}
                                                                       dola={product?.price && (((product.price / allPrice) * 100).toFixed(2))}
                                                                       typeDiscond={typeDiscond}
                                                                       add={false}
                                                                       itemOpen={itemOpen}
                                                                       update_skidka={async (count) => {

                                                                           if (item?.type_discont == 'percent') {
                                                                               if (parseInt(count) > 100) {
                                                                                   count = 100;
                                                                               }
                                                                           } else {
                                                                               if (parseInt(count) > parseInt(product.price)) {
                                                                                   count = product.price;
                                                                               }
                                                                           }

                                                                           let saveItem = {...item};
                                                                           let updateSdr = [...saveItem.advertisement_product_list].map((box) => {
                                                                               if (box.id == product.id) {
                                                                                   return {...box, ...{discond: count}};
                                                                               }
                                                                               return box;
                                                                           })
                                                                           setIIIIs({...item, ...{advertisement_product_list: updateSdr}});
                                                                           clearTimeout(timerSave);
                                                                           setTimer(
                                                                               setTimeout(() => {
                                                                                   updateProduct({
                                                                                       ...{
                                                                                           "discond": count,
                                                                                       }
                                                                                   }, product['id'])
                                                                               }, 1000)
                                                                           )

                                                                           // setIIIIs((await getAdvOpen(itemOpen.id)).data);
                                                                       }}
                                                                       discondMinus={discondMinus} discond={discond}
                                                                       th={index == 0}/>);
                                                })
                                            }
                                            {
                                                [...(new Array(parseInt(item.product_count) - item.advertisement_product_list.length))].map((itemOpen_2, index) => {
                                                    return (<AdvItemCustom
                                                        itemOpen={itemOpen}
                                                        removeCh={removeCh}
                                                        th={(index == 0 && item.advertisement_product_list.length == 0)}/>);
                                                })
                                            }
                                        </div>

                                        {
                                             (
                                                <div className="btns_adv_setting">

                                                    <p onClick={() => {
                                                        if (state_in.user.id == itemOpen.user_id.id) {
                                                            setOpenSetting(true);
                                                            setOpenModal(true);
                                                        }

                                                    }} className={'text btn-adv-new-margin text-bold text-s18'}>
                                                        Настройки
                                                    </p>


                                                    {
                                                        itemOpen.type != 'done' && (
                                                            <>
                                                                <div onClick={async () => {


                                                                    if ((item.user_list.filter((user) => {
                                                                        return user.status == 'done';
                                                                    })).length + 1 >= item.player) {
                                                                        NotificationManager.error('Вступить нельзя, все места заняты', '');
                                                                    } else {

                                                                        if (!state_in.token) {
                                                                            NotificationManager.info('Пройдите авторизацию', 'Error');
                                                                        } else {
                                                                            if (item.user_id.id == state_in.user.id) {
                                                                                NotificationManager.error('Вы владелец выйти, нельзя', '');
                                                                            } else {

                                                                                let user_info = (await getAdvOpen(item.id)).data;
                                                                                let is_join_d = (user_info.user_list.filter((itemsa) => {
                                                                                    return itemsa.user_id.id == state_in.user.id;
                                                                                }));

                                                                                if (item.open == 'Свободный доступ') {
                                                                                    if (!is_join_d.length > 0) {
                                                                                        await updateDB({
                                                                                            'save': {
                                                                                                advertisement_id: item.id,
                                                                                                user_id: state_in.user.id,
                                                                                                status: "done",
                                                                                            },
                                                                                            'id': 0,
                                                                                            'model_name': 'AdvertisementUser',
                                                                                        });
                                                                                        NotificationManager.info('Вы вступили', '');
                                                                                    } else {

                                                                                        await deleteDBAdvUser({
                                                                                            id: is_join_d[0].id,
                                                                                        });
                                                                                        NotificationManager.info('Вы покинули группу', '');
                                                                                    }

                                                                                } else {

                                                                                    if (!is_join_d.length > 0) {
                                                                                        await updateDB({
                                                                                            'save': {
                                                                                                advertisement_id: item.id,
                                                                                                user_id: state_in.user.id,
                                                                                                status: "join",
                                                                                            },
                                                                                            'id': 0,
                                                                                            'model_name': 'AdvertisementUser',
                                                                                        });
                                                                                        NotificationManager.info('Запрос отправлен', '');
                                                                                    } else {
                                                                                        NotificationManager.info('Вы уже отправили запрос', '');
                                                                                    }


                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    removeCh();

                                                                }}
                                                                     className="btn btn-adv-new-margin btn-adv-new btn-adv-new-green text text-s18">
                                                                    {
                                                                        ((item.user_id.id != state_in.user.id) && (item.user_list.filter((itemsa) => {
                                                                            return itemsa.user_id.id == state_in.user.id;
                                                                        })).length == 0) && 'Вступить'
                                                                    }
                                                                    {
                                                                        ((item.user_id.id == state_in.user.id) || (item.user_list.filter((itemsa) => {
                                                                            return itemsa.user_id.id == state_in.user.id;
                                                                        })).length > 0) && 'Покинуть'
                                                                    }
                                                                </div>


                                                                <div
                                                                    onClick={async () => {

                                                                        if (userMap.length == userListCh.filter((userListChFilter) => {
                                                                            return userListChFilter != '';
                                                                        }).length) {

                                                                            setLoad(true);

                                                                            await updateDB({
                                                                                'save': {
                                                                                    type: 'done',
                                                                                },
                                                                                'id': itemOpen.id,
                                                                                'model_name': 'Advertisement',
                                                                            });

                                                                            setLoad(false);


                                                                        } else {
                                                                            NotificationManager.info('Не все пользователи готовы', '');
                                                                        }

                                                                    }}
                                                                    className="btn btn-adv-new-margin btn-adv-new btn-adv-new-grey text text-s18">
                                                                    Начать выкуп
                                                                </div>
                                                            </>
                                                        )
                                                    }


                                                </div>
                                             )
                                        }


                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="ci-adv-new__right">
                        <div className="ci-adv-new-user">
                            <div className="ci-adv-new-user__head">
                                <p className={'text text-bold text-s18'}>
                                    Участник
                                </p>
                                <p style={{width: "7.5rem"}} className={'text text-bold text-s18'}>
                                    К оплате
                                </p>
                            </div>


                            {
                                (load) && (
                                    <div className={'fix-text-box-load'}>
                                        <p className={'text text-s18'}>
                                            Идет загрузка
                                        </p>
                                    </div>
                                )
                            }

                            <div className="ci-adv-new-user__body">
                                {
                                    userMap.map((itemSavs) => {
                                        return (
                                            <div className={'user-el-adv-ch'}>
                                                <span className={'user-icon-el'}></span>
                                                <span
                                                    className={'user-el-adv-name text text-bold text-s16'}>{itemSavs.userName}</span>
                                                <span className={'text text-bold text-s16'}>{itemSavs.price} тг</span>
                                                <span onClick={async () => {

                                                    if (itemOpen.type == 'done') {

                                                        NotificationManager.info('Выкуп начат', '');

                                                    } else {


                                                        setLoad(true);
                                                        if (itemSavs.user_id == state_in.user.id) {

                                                            let advOpenC = ((await getAdvOpen(itemOpen.id)).data);

                                                            let userChList = (advOpenC?.user_ch_list ? advOpenC?.user_ch_list : '').split(',');

                                                            if ( userChList.includes(''+state_in.user.id)) {
                                                                userChList = userChList.filter((userChListFilter) => {
                                                                    return userChListFilter != ''+state_in.user.id;
                                                                });
                                                                setChBtn(true);
                                                            } else {
                                                                userChList.push(''+state_in.user.id);
                                                                setChBtn(false);
                                                            }

                                                            setUserListCh(userChList);
                                                            await updateDB({
                                                                'save': {
                                                                    user_ch_list: userChList.join(','),
                                                                },
                                                                'id': itemOpen.id,
                                                                'model_name': 'Advertisement',
                                                            });

                                                            // user_ch_list
                                                        }
                                                        setLoad(false);
                                                    }

                                                }}
                                                      className={'ch_btn_adv ' + ( userListCh.includes(''+itemSavs.user_id) && 'ch_btn_adv-active')}></span>
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                isOpen={openModal}
                onRequestClose={() => {
                    setOpenModal(false);

                    clearInterval(timerClear);
                    setTimerClear(false);
                }}
                contentLabel="Example Modal"
                className="model-add-adb model-add-adb-flex-cons"
            >
                <form method={'post'} onSubmit={async (item) => {

                }} className="model-adv"
                      style={{
                          overflow: 'inherit',
                          borderTop: 'none',
                      }}>

                    <div className="model-add_head model-add_nav" style={{borderBottom: 0}}>
                        <p className={'text text-s20 btn-nav-link active text-w700'}
                           style={{
                               width: "calc(100% + 3px)",
                               marginLeft: "-2px",
                               left: '1px',
                               padding: '1.5rem 0',
                               borderRadius: "1.38rem"
                           }}>
                            Запрос №{item.id}: {item.name}
                        </p>
                    </div>


                    <div className="model-adv__main">
                        <div className={'model-adv__main_body'}>
                            <div className="adv__list">
                                {
                                    item.advertisement_product_list.map((product, index) => {


                                        const index_save = index;

                                        let discond = 0;
                                        let discondMinus = 0;
                                        let typeDiscond = '';


                                        let allPrice = item.advertisement_product_list.reduce(function (previousValue, currentValue) {
                                            if (priceMin == null) {
                                                setPriceMin(parseInt(currentValue.price));
                                            } else if (priceMin > parseInt(currentValue.price)) {
                                                setPriceMin(parseInt(currentValue.price));
                                            }
                                            if (priceMax == null) {
                                                setPriceMax(parseInt(currentValue.price));
                                            } else if (priceMax < parseInt(currentValue.price)) {
                                                setPriceMax(parseInt(currentValue.price));
                                            }

                                            return previousValue + parseInt(currentValue.price);
                                        }, 0)

                                        let typeDiscondControl = listType.filter((ds) => {
                                            return item.condition_discount == ds[0];
                                        });

                                        typeDiscondControl = typeDiscondControl[0][1];

                                        if (item?.type_discont == 'percent') {
                                            typeDiscond = "%";
                                            if (typeDiscondControl == 0) {
                                                discond = item.count_discont;
                                                discondMinus = product.price * (item.count_discont / 100);
                                            }
                                            if (typeDiscondControl == -1) {
                                                if (product.price == priceMin) {
                                                    discond = item.count_discont;
                                                    discondMinus = product.price * (item.count_discont / 100);
                                                }
                                            }
                                            if (typeDiscondControl == -2) {
                                                if (product.price == priceMax) {
                                                    discond = item.count_discont;
                                                    discondMinus = product.price * (item.count_discont / 100);
                                                }
                                            }
                                            if (typeDiscondControl == 2) {
                                                if ((index + 1) % 2 == 0) {
                                                    discond = item.count_discont;
                                                    discondMinus = product.price * (item.count_discont / 100);
                                                }
                                            }
                                            if (typeDiscondControl == 3) {
                                                if ((index + 1) % 3 == 0) {
                                                    discond = item.count_discont;
                                                    discondMinus = product.price * (item.count_discont / 100);
                                                }
                                            }
                                            if (typeDiscondControl == 4) {
                                                if ((index + 1) % 4 == 0) {
                                                    discond = item.count_discont;
                                                    discondMinus = product.price * (item.count_discont / 100);
                                                }
                                            }
                                            if (typeDiscondControl == 5) {
                                                discond = parseInt(product.discond);
                                                discondMinus = parseInt(product.price) * (discond / 100);
                                            }
                                        } else {
                                            let priceCurrent = 0;

                                            item.advertisement_product_list.map((pris, indexsas) => {
                                                if (index_save > indexsas) {
                                                    if (typeDiscondControl == 0) {
                                                        priceCurrent += parseInt(pris.price);
                                                    }
                                                    if (typeDiscondControl == -1) {
                                                        if (pris.price == priceMin) {
                                                            priceCurrent += parseInt(pris.price);
                                                        }
                                                    }
                                                    if (typeDiscondControl == -2) {
                                                        if (pris.price == priceMax) {
                                                            priceCurrent += parseInt(pris.price);
                                                        }
                                                    }
                                                    if (typeDiscondControl == 2) {
                                                        if ((indexsas + 1) % 2 == 0) {
                                                            priceCurrent += parseInt(pris.price);
                                                        }
                                                    }
                                                    if (typeDiscondControl == 3) {
                                                        if ((indexsas + 1) % 3 == 0) {
                                                            priceCurrent += parseInt(pris.price);
                                                        }
                                                    }
                                                    if (typeDiscondControl == 4) {
                                                        if ((indexsas + 1) % 4 == 0) {
                                                            priceCurrent += parseInt(pris.price);
                                                        }
                                                    }

                                                }
                                            });

                                            typeDiscond = 'тнг';
                                            if (typeDiscondControl == 0) {
                                                discond = (product.price - item.count_discont) < 0 ? product.price : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                discondMinus = discond;
                                            }
                                            if (typeDiscondControl == -1) {
                                                if (parseInt(product.price) == priceMin) {
                                                    discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                    discondMinus = discond;
                                                }
                                            }
                                            if (typeDiscondControl == -2) {
                                                if (parseInt(product.price) == priceMax) {
                                                    discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                    discondMinus = discond;
                                                }
                                            }
                                            if (typeDiscondControl == 2) {
                                                if ((index + 1) % 2 == 0) {
                                                    discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                    discondMinus = discond;
                                                }
                                            }
                                            if (typeDiscondControl == 3) {
                                                if ((index + 1) % 3 == 0) {
                                                    discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                    discondMinus = discond;
                                                }
                                            }
                                            if (typeDiscondControl == 4) {
                                                if ((index + 1) % 4 == 0) {
                                                    discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                    discondMinus = discond;
                                                }
                                            }

                                            if (typeDiscondControl == 5) {
                                                discond = parseInt(product.discond);
                                                discondMinus = discond;
                                            }
                                            // if (discondMinus > product.price) {
                                            //     discondMinus = product.price;
                                            // }
                                        }

                                        // if (typeDiscondControl == 5 || typeDiscondControl == 6) {
                                        //     discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                        //     discondMinus = 0;
                                        // }

                                        let codeType = typeDiscondControl;

                                        return (
                                            <AdvItem item={product}
                                                     priceAllDiscond={priceAllDiscond}
                                                     removeCh={removeCh}
                                                     codeType={codeType}
                                                     dola={product?.price && (((product.price / allPrice) * 100).toFixed(2))}
                                                     typeDiscond={typeDiscond}
                                                     add={false}
                                                     itemOpen={itemOpen}
                                                     update_skidka={async (count) => {

                                                         if (item?.type_discont == 'percent') {
                                                             if (parseInt(count) > 100) {
                                                                 count = 100;
                                                             }
                                                         } else {
                                                             if (parseInt(count) > parseInt(product.price)) {
                                                                 count = product.price;
                                                             }
                                                         }

                                                         let saveItem = {...item};
                                                         let updateSdr = [...saveItem.advertisement_product_list].map((box) => {
                                                             if (box.id == product.id) {
                                                                 return {...box, ...{discond: count}};
                                                             }
                                                             return box;
                                                         })
                                                         setIIIIs({...item, ...{advertisement_product_list: updateSdr}});
                                                         clearTimeout(timerSave);
                                                         setTimer(
                                                             setTimeout(() => {
                                                                 updateProduct({
                                                                     ...{
                                                                         "discond": count,
                                                                     }
                                                                 }, product['id'])
                                                             }, 1000)
                                                         )

                                                         // setIIIIs((await getAdvOpen(itemOpen.id)).data);
                                                     }}
                                                     discondMinus={discondMinus} discond={discond}
                                                     th={index == 0}/>);
                                    })
                                }
                                {
                                    [...(new Array(parseInt(item.product_count) - item.advertisement_product_list.length))].map((itemOpen_2, index) => {
                                        return (<AdvItem
                                            itemOpen={itemOpen}
                                            removeCh={removeCh}
                                            th={(index == 0 && item.advertisement_product_list.length == 0)}/>);
                                    })
                                }
                            </div>
                            <div className="adv__main adv__main--fix adv__main--fix-top">
                                <div className="adv__user adv__user--fix sm-s33">
                                    <div className="adv-td">
                                        <div className="adv-user">

                                        </div>
                                    </div>
                                </div>

                                <div className="adv__group">
                                    <div className="adv-td">
                                        <p style={{color: "#FF0000"}} className={'text text-w700 text-s20'}>
                                            {
                                                numberWithSpaces(item.advertisement_product_list.reduce(function (previousValue, currentValue) {
                                                    return previousValue + parseInt(currentValue.price);
                                                }, 0))
                                            } тг
                                        </p>


                                        <div className="adv-add-input">

                                        </div>

                                    </div>
                                </div>

                                <div className="adv__group">
                                    <div className="adv-td"
                                         style={{
                                             paddingLeft: "1rem",
                                             display: "flex",
                                             flexDirection: "row",
                                             alignItems: "flex-start"
                                         }}>
                                        <div className="adv-add-input" style={{width: "50%"}}>
                                            <div className={' text text-s20'}>
                                                {item?.count_discont}
                                                {item?.type_discont == 'percent' && ' %'}
                                                {item?.type_discont == 'currency' && ' тнг'}
                                            </div>
                                        </div>

                                        <div style={{marginLeft: "auto", width: "50%", paddingLeft: "3rem"}}>
                                            <p style={{color: "#127634"}} className={'text text-w700 text-s20'}>
                                                {
                                                    numberWithSpaces(item.advertisement_product_list.reduce(function (previousValue, product, index) {
                                                            const index_save = index;
                                                            let discond = 0;
                                                            let discondMinus = 0;
                                                            let typeDiscond = '';


                                                            let allPrice = item.advertisement_product_list.reduce(function (previousValue, currentValue) {
                                                                if (priceMin == null) {
                                                                    setPriceMin(parseInt(currentValue.price));
                                                                } else if (priceMin > parseInt(currentValue.price)) {
                                                                    setPriceMin(parseInt(currentValue.price));
                                                                }
                                                                if (priceMax == null) {
                                                                    setPriceMax(parseInt(currentValue.price));
                                                                } else if (priceMax < parseInt(currentValue.price)) {
                                                                    setPriceMax(parseInt(currentValue.price));
                                                                }

                                                                return previousValue + parseInt(currentValue.price);
                                                            }, 0)

                                                            let typeDiscondControl = listType.filter((ds) => {
                                                                return item.condition_discount == ds[0];
                                                            });
                                                            typeDiscondControl = typeDiscondControl[0][1];

                                                            if (item?.type_discont == 'percent') {
                                                                typeDiscond = "%";
                                                                if (typeDiscondControl == 0) {
                                                                    discond = item.count_discont;
                                                                    discondMinus = product.price * (item.count_discont / 100);
                                                                }
                                                                if (typeDiscondControl == -1) {
                                                                    if (product.price == priceMin) {
                                                                        discond = item.count_discont;
                                                                        discondMinus = product.price * (item.count_discont / 100);
                                                                    }
                                                                }
                                                                if (typeDiscondControl == -2) {
                                                                    if (product.price == priceMax) {
                                                                        discond = item.count_discont;
                                                                        discondMinus = product.price * (item.count_discont / 100);
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 2) {
                                                                    if ((index + 1) % 2 == 0) {
                                                                        discond = item.count_discont;
                                                                        discondMinus = product.price * (item.count_discont / 100);
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 3) {
                                                                    if ((index + 1) % 3 == 0) {
                                                                        discond = item.count_discont;
                                                                        discondMinus = product.price * (item.count_discont / 100);
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 4) {
                                                                    if ((index + 1) % 4 == 0) {
                                                                        discond = item.count_discont;
                                                                        discondMinus = product.price * (item.count_discont / 100);
                                                                    }
                                                                }

                                                                if (typeDiscondControl == 5) {
                                                                    discond = parseInt(product.discond);
                                                                    discondMinus = parseInt(product.price) * (discond / 100);
                                                                }
                                                            } else {
                                                                let priceCurrent = 0;

                                                                item.advertisement_product_list.map((pris, indexsas) => {
                                                                    if (index_save > indexsas) {
                                                                        if (typeDiscondControl == 0) {
                                                                            priceCurrent += parseInt(pris.price);
                                                                        }
                                                                        if (typeDiscondControl == -1) {
                                                                            if (pris.price == priceMin) {
                                                                                priceCurrent += parseInt(pris.price);
                                                                            }
                                                                        }
                                                                        if (typeDiscondControl == -2) {
                                                                            if (pris.price == priceMax) {
                                                                                priceCurrent += parseInt(pris.price);
                                                                            }
                                                                        }
                                                                        if (typeDiscondControl == 2) {
                                                                            if ((indexsas + 1) % 2 == 0) {
                                                                                priceCurrent += parseInt(pris.price);
                                                                            }
                                                                        }
                                                                        if (typeDiscondControl == 3) {
                                                                            if ((indexsas + 1) % 3 == 0) {
                                                                                priceCurrent += parseInt(pris.price);
                                                                            }
                                                                        }
                                                                        if (typeDiscondControl == 4) {
                                                                            if ((indexsas + 1) % 4 == 0) {
                                                                                priceCurrent += parseInt(pris.price);
                                                                            }
                                                                        }


                                                                    }
                                                                });

                                                                typeDiscond = 'тнг';
                                                                if (typeDiscondControl == 0) {
                                                                    discond = (product.price - item.count_discont) < 0 ? product.price : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                    discondMinus = discond;
                                                                }
                                                                if (typeDiscondControl == -1) {
                                                                    if (parseInt(product.price) == priceMin) {
                                                                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                        discondMinus = discond;
                                                                    }
                                                                }
                                                                if (typeDiscondControl == -2) {
                                                                    if (parseInt(product.price) == priceMax) {
                                                                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                        discondMinus = discond;
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 2) {
                                                                    if ((index + 1) % 2 == 0) {
                                                                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                        discondMinus = discond;
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 3) {
                                                                    if ((index + 1) % 3 == 0) {
                                                                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                        discondMinus = discond;
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 4) {
                                                                    if ((index + 1) % 4 == 0) {
                                                                        discond = (product.price - (item.count_discont)) < 0 ? (priceCurrent > 0 ? (product.price > (item.count_discont - priceCurrent) ? (item.count_discont - priceCurrent) : product.price) : product.price) : (priceCurrent > item.count_discont ? 0 : (item.count_discont - priceCurrent));
                                                                        discondMinus = discond;
                                                                    }
                                                                }
                                                                if (typeDiscondControl == 5) {
                                                                    discond = parseInt(product.discond);
                                                                    discondMinus = discond;
                                                                }
                                                                // if (discondMinus > product.price) {
                                                                //     discondMinus = product.price;
                                                                // }
                                                            }

                                                            return previousValue + parseInt(parseInt(product.price) - parseInt(discondMinus));
                                                        }, 0)
                                                    )}
                                                тг
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="adv__group">
                                    <div className="adv-td" style={{paddingLeft: "1.8rem"}}>
                                    </div>
                                </div>

                                <div className="navigation-adv">

                                    {
                                        itemOpen.type != 'done' && (
                                            <div className="input input-wrap">
                                                <p dangerouslySetInnerHTML={{__html: 'Формат скидки'}}
                                                   className="input_lab input_lab-full text text-s20">
                                                </p>

                                                <select disabled={item.user_id.id != state_in.user.id} onChange={(e) => {

                                                    let current_tenget = null;
                                                    if (e.target.value == 'percent') {
                                                        if (item.count_discont > 100) {
                                                            current_tenget = 100;
                                                        }
                                                    } else {

                                                    }
                                                    updateDbSave({
                                                        ...{
                                                            "type_discont": e.target.value,
                                                        },
                                                        ...{
                                                            count_discont: current_tenget == null ? item.count_discont : current_tenget
                                                        }
                                                    })
                                                }}
                                                        className={'input_in text text-s16'}>
                                                    <option selected={item?.type_discont == 'percent'}
                                                            value={'percent'}>Размер
                                                        скидки в %
                                                    </option>
                                                    <option selected={item?.type_discont == 'currency'}
                                                            value={'currency'}>Размер
                                                        скидки в тнг
                                                    </option>
                                                </select>

                                            </div>
                                        )
                                    }


                                    {
                                        itemOpen.type != 'done' && <>
                                            <div className="input input-wrap">
                                                <p dangerouslySetInnerHTML={{__html: 'Размер скидки'}}
                                                   className="input_lab input_lab-full text text-s20">
                                                </p>

                                                {
                                                    item.condition_discount != 'Выставить вручную' && (
                                                        <input
                                                            disabled={item.user_id.id != state_in.user.id}
                                                            onChange={(e) => {

                                                                let current_tenget = e.target.value.replace(/[^+\d]/g, '');
                                                                if (current_tenget < 0) {
                                                                    current_tenget = 0;
                                                                }
                                                                if (item?.type_discont == 'percent') {
                                                                    if (current_tenget > 100) {
                                                                        current_tenget = 100;
                                                                    }
                                                                }

                                                                setItem({
                                                                    ...item,
                                                                    ...{
                                                                        count_discont: current_tenget
                                                                    }
                                                                })

                                                                updateDbSave({
                                                                    "count_discont": current_tenget
                                                                });
                                                                setReset(!reset);
                                                            }}
                                                            value={item?.count_discont}
                                                            className={'input_in text text-s16'}/>
                                                    )
                                                }

                                            </div>


                                            <div className="input input-wrap">
                                                <p dangerouslySetInnerHTML={{__html: 'Условие скидки'}}
                                                   className="input_lab input_lab-full text text-s20">
                                                </p>

                                                <select
                                                    disabled={item.user_id.id != state_in.user.id}
                                                    onChange={(e) => {
                                                        updateDbSave({
                                                            "condition_discount": e.target.value
                                                        })
                                                        setReset(!reset);
                                                    }}
                                                    className={'input_in text text-s16'}>
                                                    {
                                                        listType.map((option) => {
                                                            return (<option selected={item.condition_discount == option[0]}
                                                                            value={option[0]}>{option[0]}</option>);
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </>
                                    }


                                </div>

                                <div className="adv__footer" style={{width: "100%"}}>
                                    <a href="javascript:void(0)" onClick={() => {
                                        setOpenModal(false);
                                    }} className={'btn btn--adv text text-s18 btn--back'}>
                                        выйти
                                    </a>
                                    <a href="javascript:void(0)" onClick={async () => {

                                        let user_info = (await getAdvOpen(item.id)).data;
                                        let is_join_d = (user_info.user_list.filter((user) => {
                                            return user.status == 'done';
                                        }).filter((itemsa) => {
                                            return itemsa.user_id.id == state_in.user.id;
                                        })).length > 0;
                                        if (is_join_d || state_in.user.id == item.user_id.id) {
                                            setOpenChat(true);
                                        } else {
                                            NotificationManager.error('Вы не состоите в группе', '');
                                        }

                                    }} className={'btn btn--adv text text-s18 btn--chat'}>
                                        Чат
                                    </a>

                                    {
                                        item.user_id?.id == state_in?.user?.id && itemOpen.type != 'done' && (
                                            <a href="javascript:void(0)" onClick={() => {

                                                setOpenSetting(true);
                                            }} className={'btn btn--adv text text-s18 btn--chat'}
                                               style={{width: "auto", backgroundColor: "#818181"}}>
                                                Настройки
                                            </a>
                                        )
                                    }

                                    {
                                        itemOpen.type != 'done' && (
                                            <a href="javascript:void(0)" onClick={async () => {


                                                if ((item.user_list.filter((user) => {
                                                    return user.status == 'done';
                                                })).length + 1 >= item.player) {
                                                    NotificationManager.error('Вступить нельзя, все места заняты', '');
                                                } else {

                                                    if (!state_in.token) {
                                                        NotificationManager.info('Пройдите авторизацию', 'Error');
                                                    } else {
                                                        if (item.user_id.id == state_in.user.id) {
                                                            NotificationManager.error('Вы владелец выйти, нельзя', '');
                                                        } else {

                                                            let user_info = (await getAdvOpen(item.id)).data;
                                                            let is_join_d = (user_info.user_list.filter((itemsa) => {
                                                                return itemsa.user_id.id == state_in.user.id;
                                                            }));

                                                            if (item.open == 'Свободный доступ') {
                                                                if (!is_join_d.length > 0) {
                                                                    await updateDB({
                                                                        'save': {
                                                                            advertisement_id: item.id,
                                                                            user_id: state_in.user.id,
                                                                            status: "done",
                                                                        },
                                                                        'id': 0,
                                                                        'model_name': 'AdvertisementUser',
                                                                    });
                                                                    NotificationManager.info('Вы вступили', '');
                                                                } else {

                                                                    await deleteDBAdvUser({
                                                                        id: is_join_d[0].id,
                                                                    });
                                                                    NotificationManager.info('Вы покинули группу', '');
                                                                }

                                                            } else {

                                                                if (!is_join_d.length > 0) {
                                                                    await updateDB({
                                                                        'save': {
                                                                            advertisement_id: item.id,
                                                                            user_id: state_in.user.id,
                                                                            status: "join",
                                                                        },
                                                                        'id': 0,
                                                                        'model_name': 'AdvertisementUser',
                                                                    });
                                                                    NotificationManager.info('Запрос отправлен', '');
                                                                } else {
                                                                    NotificationManager.info('Вы уже отправили запрос', '');
                                                                }


                                                            }
                                                        }
                                                    }
                                                }
                                                removeCh();

                                            }} className={'btn btn--adv text text-s18 btn--join'}>

                                                {
                                                    ((item.user_id.id != state_in.user.id) && (item.user_list.filter((itemsa) => {
                                                        return itemsa.user_id.id == state_in.user.id;
                                                    })).length == 0) && 'Вступить'
                                                }
                                                {
                                                    ((item.user_id.id == state_in.user.id) || (item.user_list.filter((itemsa) => {
                                                        return itemsa.user_id.id == state_in.user.id;
                                                    })).length > 0) && 'Покинуть'
                                                }
                                            </a>
                                        )
                                    }


                                </div>
                            </div>


                        </div>
                        <div className={"model-adv__main__chat " + (openChat && 'active')}>

                            <div className="chat_box">
                                <div className="chat_box_message">
                                    {
                                        [...messageChat].filter((message) => {
                                            return message.advertisement_id == itemOpen.id;
                                        }).map((items) => {
                                            return (
                                                <div
                                                    className={"user_message " + (items.user_id.id == state_in.user.id && "user_message-me")}>
                                                    <div className="user_message_img"
                                                         style={{backgroundImage: "url('https://admin.disconty.kz/" + items.user_id.image + "')"}}>

                                                    </div>
                                                    <div className="user_message_message">
                                                        <p className={'text text-s16'}>
                                                            {items.comment}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }


                                </div>
                            </div>

                            <div className="adv__footer">
                                <a href="javascript:void(0)" onClick={() => {
                                    setOpenChat(false);
                                }} className={'btn btn--adv text text-s18 btn--back'}>
                                    Назад
                                </a>

                                <textarea className="comment_text text text-s14" onChange={(e) => {
                                    setMessage(e.target.value);
                                }}
                                          placeholder={"Введите сообщение"} value={message_text}/>

                                <a href="javascript:void(0)" onClick={async () => {

                                    await updateDB({
                                        'save': {
                                            comment: message_text,
                                            user_id: state_in.user.id,
                                            advertisement_id: itemOpen.id,
                                        },
                                        'id': 0,
                                        'model_name': 'Chat',
                                    });
                                    setMessage("");
                                    await setMessageChat((await getChat()).data);
                                }} className={'btn btn--adv text text-s18 btn--join'}
                                   style={{borderTopLeftRadius: 0, width: "auto"}}>
                                    Отправить
                                </a>
                            </div>
                        </div>


                        <div className={"model-adv__main__chat " + (openSetting && 'active')}>

                            <div className="chat_box">
                                <div className="chat_box_message chat_box_message-setting">
                                    <p className={'text text-s24'}>
                                        Настройки
                                    </p>
                                </div>

                                <div className="box_controls--el">
                                    <div className="user_listing">
                                        {
                                            item.user_list.map((items) => {

                                                return (
                                                    <div
                                                        className={"user_message user_message_join " + (items.user_id.id == state_in.user.id && "user_message-me")}>
                                                        <div className="user_message_img"
                                                             style={{backgroundImage: "url('https://admin.disconty.kz/" + items.user_id.image + "')"}}>

                                                        </div>
                                                        <div className="user_message_message">
                                                            <p className={'text text-s16'}>
                                                                {items.user_id.name}
                                                            </p>
                                                        </div>
                                                        <div className="edit-items">
                                                            {
                                                                items.status == 'done' && (
                                                                    <div style={{display: "flex"}}>
                                                                        <a onClick={async () => {
                                                                            if (confirm('Вы действительно хотите передать лидерство пользователю ' + items.user_id.name)) {
                                                                                await updateDB({
                                                                                    'save': {
                                                                                        user_id: items.user_id.id
                                                                                    },
                                                                                    'id': itemOpen.id,
                                                                                    'model_name': 'Advertisement',
                                                                                });
                                                                                await updateDB({
                                                                                    'save': {
                                                                                        advertisement_id: itemOpen.id,
                                                                                        user_id: state_in.user.id,
                                                                                        status: "done",
                                                                                    },
                                                                                    'id': 0,
                                                                                    'model_name': 'AdvertisementUser',
                                                                                });
                                                                                await deleteDBAdvUser({
                                                                                    id: items.id,
                                                                                });
                                                                                removeCh();
                                                                                setOpenSetting(false);
                                                                            }
                                                                        }} className={'text text-s18'}
                                                                           style={{color: "#9D00FF", marginRight: "2rem"}}>
                                                                            Передать лидерство
                                                                        </a>
                                                                        <a onClick={async () => {
                                                                            if (confirm('Вы действительно хотите выгнать пользователя ' + items.user_id.name)) {
                                                                                await deleteDBAdvUser({
                                                                                    id: items.id,
                                                                                });
                                                                            }
                                                                        }} className={'text text-s18'}
                                                                           style={{color: "red"}}>
                                                                            Выгнать
                                                                        </a>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                items.status == 'join' && (
                                                                    <a onClick={async () => {

                                                                        await updateDB({
                                                                            'save': {
                                                                                status: "done",
                                                                            },
                                                                            'id': items['id'],
                                                                            'model_name': 'AdvertisementUser',
                                                                        });
                                                                        NotificationManager.info('Принят в группу', '');
                                                                        removeCh();
                                                                    }} className={'text text-s18'} style={{color: "green"}}>
                                                                        Принять
                                                                    </a>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className="user_controls_bella" style={{flexWrap: "wrap"}}>

                                        <div style={{width: "100%"}}>
                                            <a href="javascript:void(0)" onClick={async () => {
                                                setOpenSetting(false);

                                                if (confirm('Вы действительно хотите завершить запрос ' + itemOpen.name)) {
                                                    await updateDB({
                                                        'save': {
                                                            status: "done",
                                                        },
                                                        'id': itemOpen.id,
                                                        'model_name': 'Advertisement',
                                                    });
                                                }
                                                NotificationManager.info('Завершение запроса, ожидайте', 'Смена статуса');
                                            }} className="btn btn-done text text-s18">
                                                Завершить запрос
                                            </a>
                                        </div>
                                        <br/>

                                        <div className={'btn-top'}>

                                            <div onClick={() => {

                                            }} className="btn_pay text text-s18">
                                                <div className="btn_pay_icon">
                                                    <img src={'/pointTop.png'}/>
                                                </div>
                                                <div className="btn_pay_info">
                                                    <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                                                        Топ - запрос на 1 день
                                                    </p>
                                                    <p className={'text text-s13'}>
                                                        Выйти в топ на 1 день
                                                    </p>
                                                </div>
                                                <a href="javascript:void(0)" onClick={async () => {

                                                    let open = await getPayBox('?id=' + itemOpen.id + "&price=200");
                                                    location.href = open[0];

                                                }} className="btn_pay_btn">
                                                    <p className={'text text-s15'}>
                                                        200 т
                                                    </p>
                                                </a>
                                            </div>

                                            <div onClick={async () => {

                                            }} className="btn_pay text text-s18">
                                                <div className="btn_pay_icon">
                                                    <img src={'/pointTop.png'}/>
                                                </div>
                                                <div className="btn_pay_info">
                                                    <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                                                        Топ - запрос на 3 дня
                                                    </p>
                                                    <p className={'text text-s13'}>
                                                        Выйти в топ на 3 дня
                                                    </p>
                                                </div>
                                                <a href="javascript:void(0)" onClick={async () => {

                                                    let open = await getPayBox('?id=' + itemOpen.id + "&price=450");
                                                    location.href = open[0];

                                                }} className="btn_pay_btn">
                                                    <p className={'text text-s15'}>
                                                        450 т
                                                    </p>
                                                </a>
                                            </div>

                                            <div className="btn_pay text text-s18">
                                                <div className="btn_pay_icon">
                                                    <img src={'/pointTop.png'}/>
                                                </div>
                                                <div className="btn_pay_info">
                                                    <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                                                        Топ - запрос на 7 дней
                                                    </p>
                                                    <p className={'text text-s13'}>
                                                        Выйти в топ на 7 дней
                                                    </p>
                                                </div>
                                                <a href="javascript:void(0)" onClick={async () => {

                                                    let open = await getPayBox('?id=' + itemOpen.id + "&price=900");
                                                    location.href = open[0];

                                                }} className="btn_pay_btn">
                                                    <p className={'text text-s15'}>
                                                        900 т
                                                    </p>
                                                </a>
                                            </div>


                                            <div className="btn_pay text text-s18">
                                                <div className="btn_pay_icon">
                                                    <img src={'/pointVip.png'}/>
                                                </div>
                                                <div className="btn_pay_info">
                                                    <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                                                        VIP - запрос на 3 дня
                                                    </p>
                                                    <p className={'text text-s13'}>
                                                        Стать VIP на 3 дня
                                                    </p>
                                                </div>
                                                <a onClick={async () => {
                                                    let open = await getPayBox('?id=' + itemOpen.id + "&price=1800");
                                                    location.href = open[0];
                                                }} className="btn_pay_btn">
                                                    <p className={'text text-s15'}>
                                                        1800 т
                                                    </p>
                                                </a>
                                            </div>


                                            <div className="btn_pay text text-s18">
                                                <div className="btn_pay_icon">
                                                    <img src={'/pointVip.png'}/>
                                                </div>
                                                <div className="btn_pay_info">
                                                    <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                                                        VIP - запрос на 7 дней
                                                    </p>
                                                    <p className={'text text-s13'}>
                                                        Стать VIP на 7 дней
                                                    </p>
                                                </div>
                                                <div onClick={async () => {
                                                    let open = await getPayBox('?id=' + itemOpen.id + "&price=3600");
                                                    location.href = open[0];
                                                }} className="btn_pay_btn">
                                                    <p className={'text text-s15'}>
                                                        3600 т
                                                    </p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="adv__footer">
                                <a href="javascript:void(0)" onClick={() => {
                                    setOpenSetting(false);
                                }} className={'btn btn--adv text text-s18 btn--back'}>
                                    Назад
                                </a>
                            </div>
                        </div>

                        {
                            item.status == 'done' && (
                                <div className={'adv_done adv_done-row'} style={{backgroundColor: "#fff"}}>
                                    <div className="user_listing user_listing-center">


                                        {
                                            itemOpen.user_id.id != state_in.user.id && (
                                                <div
                                                    className={"user_message user_message_join "} onClick={async () => {

                                                }}>
                                                    <div className="user_message_img"
                                                         style={{backgroundImage: "url('https://admin.disconty.kz/" + itemOpen.user_id.image + "')"}}>

                                                    </div>
                                                    <div className="user_message_message">
                                                        <p className={'text text-s16'}>
                                                            {itemOpen.user_id.name}
                                                        </p>
                                                    </div>
                                                    <div className="edit-items">
                                                        {
                                                            (item?.reviews_list.filter((users) => {
                                                                return users.user_from == state_in.user.id && users.user_id == item.user_id.id;
                                                            }).length > 0 ? (
                                                                <div style={{display: "flex"}}>
                                                                    <a onClick={async () => {

                                                                    }} className={'text btn-rows  text-s18'}>
                                                                        Вы уже оставили отзыв
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                <div style={{display: "flex"}}>
                                                                    <a onClick={async () => {
                                                                        setTitleName('отзыв лидеру запроса');
                                                                        setUserRow(itemOpen.user_id.id);
                                                                        setOpenRew(true);
                                                                    }} className={'text btn-rows  text-s18'}>
                                                                        оставить отзыв лидеру запроса
                                                                    </a>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }

                                        {
                                            item.user_list.map((items) => {

                                                let rowTrue = item.reviews_list.filter((users) => {
                                                    return users.user_from == state_in.user.id && users.user_id == items.user_id.id;
                                                }).length > 0;

                                                if (items.user_id.id == state_in.user.id) {
                                                    return null;
                                                }
                                                return (
                                                    <div
                                                        className={"user_message user_message_join " + (items.user_id.id == state_in.user.id && "user_message-me")}>
                                                        <div className="user_message_img"
                                                             style={{backgroundImage: "url('https://admin.disconty.kz/" + items.user_id.image + "')"}}>

                                                        </div>
                                                        <div className="user_message_message">
                                                            <p className={'text text-s16'}>
                                                                {items.user_id.name}
                                                            </p>
                                                        </div>
                                                        <div className="edit-items">
                                                            {
                                                                items.status == 'done' && (
                                                                    (item?.reviews_list.filter((users) => {
                                                                        return users.user_from == state_in.user.id && users.user_id == items.user_id.id;
                                                                    }).length > 0 ? (
                                                                        <div style={{display: "flex"}}>
                                                                            <a onClick={async () => {

                                                                            }} className={'text btn-rows  text-s18'}>
                                                                                Вы уже оставили отзыв
                                                                            </a>
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{display: "flex"}}>
                                                                            <a onClick={async () => {
                                                                                setTitleName('отзыв лидеру запроса');
                                                                                setUserRow(items.user_id.id);
                                                                                setOpenRew(true);
                                                                            }} className={'text btn-rows  text-s18'}>
                                                                                оставить отзыв
                                                                            </a>
                                                                        </div>
                                                                    ))
                                                                )
                                                            }
                                                            {
                                                                items.status == 'join' && (
                                                                    <a onClick={async () => {

                                                                        await updateDB({
                                                                            'save': {
                                                                                status: "done",
                                                                            },
                                                                            'id': items['id'],
                                                                            'model_name': 'AdvertisementUser',
                                                                        });
                                                                        NotificationManager.info('Принят в группу', '');
                                                                        removeCh();
                                                                    }} className={'text text-s18'} style={{color: "green"}}>
                                                                        Принять
                                                                    </a>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }


                                        {
                                            <div className={'open_moda_row ' + (openRew && 'active')}>
                                                <div className="open_moda_row_head">
                                                    <p className={'text text-s22'}>
                                                        {titleName}
                                                    </p>
                                                </div>
                                                <div className="open_moda_row_body">
                                                    <p className={'text text-s16'}>
                                                        Текстовый отзыв
                                                    </p>
                                                    <textarea value={rewName} onChange={(e) => {
                                                        setRewName(e.target.value);
                                                    }} className={'textarea-commit text text-s16'}
                                                              placeholder={'Напишите отзыв:'}></textarea>

                                                    <div className="star_rows">
                                                        <p className={'text text-s16'}>
                                                            Оцените по 10 бальной шкале
                                                        </p>
                                                        <div className={'star-list'}>
                                                            <div className="star-list_star">
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((itemStar) => {
                                                                        return (
                                                                            <img onClick={() => {
                                                                                setStarNum(itemStar);
                                                                            }} className={'star_btn'}
                                                                                 src={starNum >= itemStar ? StarFull : StarEmpty}/>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                            <div className="star-list_num text text-s20">
                                                                {starNum}/10
                                                            </div>
                                                        </div>
                                                        <div className="btn_box-raw">
                                                            <div onClick={async () => {

                                                                await updateDB({
                                                                    'save': {
                                                                        user_from: state_in.user.id,
                                                                        advertisement_id: item.id,
                                                                        user_id: userRow,
                                                                        comment: rewName,
                                                                        rait: starNum,
                                                                    },
                                                                    'id': 0,
                                                                    'model_name': 'Reviews',
                                                                });

                                                                await setItem((await getAdvOpen(item.id)).data);
                                                                setRewName("");
                                                                setStarNum(1);
                                                                setOpenRew(false);
                                                            }} className="btn btn-add text text-s16">Оставить отзыв
                                                            </div>
                                                            <div onClick={() => {
                                                                setOpenRew(false);
                                                            }} className="btn btn-cansel btn-add text text-s16">Отмена
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </form>
            </Modal>


        </Layout>
    );
});
export default MainScreen;
