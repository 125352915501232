import './style.scss'

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";
import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import SelectSearch from 'react-select';
import {getAdv, getChat} from "../../services";
import Adv from "../../components/Adv";

import One from "../../assets/images/1.png";
import Two from "../../assets/images/2.png";
import TreeImg from "../../assets/images/3.png";
import Modal from "react-modal";

const MainScreen = (() => {

    const [page, setPage] = useState(false);
    const [modal, setModal] = useState(false);
    function showModal(){

    }
    return (
        <Layout>
            <div className="container about">


                <Modal
                    isOpen={modal}
                    onRequestClose={()=>{
                        setModal(false);
                    }}
                    contentLabel="Example Modal"
                    className="video-modal"
                >
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/3IY7GXWWgsQ"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>

                    <div onClick={()=>{
                        setModal(false);
                    }} className="btn btn-close-btn btn btn-add  text text-s18">
                        Закрыть
                    </div>
                </Modal>

                <div className="about_body">
                    <div className="vip">
                        <div className="vip_box">
                            <p className={'text text-s32'} style={{fontWeight: "bold"}}>
                                о Disconty.kz
                            </p>
                            <p className={'text text-s16'} style={{color: "#A0A0A2"}}>
                                Объединись и купи со скидкой
                            </p>
                            <p onClick={() => {
                                setModal(true);
                            }} className={'text center_text text-s16'}>
                                <img className={'img-play'} src={'/play.png'}/> Посмотреть промо видео
                            </p>
                        </div>
                        <div className="vip_box">
                            <p className={'text text-s14'} style={{fontWeight: "bold"}}>
                                1.
                            </p>
                            <br/>
                            <p className={'text text-s14'} style={{fontWeight: "bold"}}>
                                Магазины дают скидки при <br/>
                                покупке нескольких товаров или оптом!
                            </p>
                            <p className={'text text-s12'}>
                                Воспользуйтесь этим и соберите команду для совместной покупки товаров и услуг.<br/>
                                Для этого вы находите акцию в любом магазине (напр. в Сулпак), где дают скидки за
                                покупку нескольких товаров, т.е. оптовая скидка.<br/>
                                Далее, вы создаете свою группу в disconty.kz (т.е. создаете “Запрос”) и ждете
                                присоединения других пользователей (или сами присоединяетесь к другой группе). Если ваш
                                Запрос упал в конец страницы, используйте услуги поднятия.
                            </p>
                        </div>
                        <div className="vip_box">
                            <p className={'text text-s14'} style={{fontWeight: "bold"}}>
                                2.
                            </p>
                            <br/>
                            <p className={'text text-s14'} style={{fontWeight: "bold"}}>
                                Договоритесь между собой и соберитесь вместе
                            </p>
                            <p className={'text text-s12'}>
                                Как только вы набрали группу для совместной покупки, обсудите условия закупа. <br/>
                                Распределите скидку между собой в зависимости от вашей доли в покупке. Т.е. чем дороже
                                ваш товар, тем больше скидки вам положено.<br/>
                                Система автоматически рассчитает размер скидки каждому в зависимости от того, какие
                                условия скидок вы выбрали (например, 20% на самый дешевый товар).
                            </p>
                        </div>
                        <div className="vip_box">
                            <p className={'text text-s14'} style={{fontWeight: "bold"}}>
                                3.
                            </p>
                            <br/>
                            <p className={'text text-s14'} style={{fontWeight: "bold"}}>
                                Купите вместе и оставьте отзыв!
                            </p>
                            <p className={'text text-s12'}>
                                В чате договоритесь о дне и месте встречи для совместной покупки. Мы советуем вам
                                встретиться всем вместе, вместе купить товар, назначить одного человека среди вас, кто
                                оплатит за товары на кассе от имени вашей группы.
                                Если вы не хотите встречаться, наймите агента disconty.kz, который выкупит товары от
                                вашего имени и организует доставку до вашего адреса. Услуга стоит 10% от сэкономленной
                                суммы, но не менее 9,000 тенге. Пока услуга доступна только в г. Алматы.
                                После успешной покупки оставьте отзыв друг-другу.
                            </p>
                        </div>
                    </div>
                    <br/>
                    <br/>

                    <p className={'text text-s24'} style={{fontWeight: "bold"}}>
                        Краткие сведения об услугах рекламирования в Disconty.kz
                    </p>
                    <br/>
                    <p className={'text text-s16'} style={{fontWeight: "bold"}}>
                        1. Что такое Топ запросы?
                    </p>
                    <p className={'text text-s16'}>
                        Топ-запрос - Запрос будет отображаться в специальном блоке над обычными Запросам. Поднятие вверх
                        списка. Запрос попадает вверх списка, словно
                        создатель Запроса только что разместил Запрос. Оно будет находиться выше, чем похожие запросы
                        без продвижения. Покраска синим цветом.
                    </p>
                    <br/>
                    <p className={'text text-s16'} style={{fontWeight: "bold"}}>
                        2. Что такое VIP запросы?
                    </p>
                    <p className={'text text-s16'}>
                        Размещение на Главной странице + Размещение в ТОП. Запрос будет отображаться в специальном блоке
                        над обычными Запросами. Поднятие вверх списка
                        – Запрос попадает вверх списка, словно создатель Запроса только что разместил Запрос. Оно будет
                        находиться выше, чем похожие запросы без продвижения.
                        Покраска золотым цветом
                    </p>
                    <br/>

                    <p className={'text text-s16'} style={{fontWeight: "bold"}}>
                        3. Как добавить свой собственный запрос?
                    </p>
                    <p className={'text text-s16'}>
                        Чтобы добавить свой запрос необходимо нажать на кнопку “+Запрос” в верхнем правом углу сайта
                    </p>
                    <br/>
                    <p className={'text text-s16'} style={{fontWeight: "bold"}}>
                        4. Как оплатить за Топ и VIP запросы?
                    </p>
                    <p className={'text text-s16'}>
                        Вы можете оплатить банковской картой в разделе “Услуги”, в личном кабинете и при создании
                        Запроса.
                    </p>
                </div>
            </div>
        </Layout>
    );
});
export default MainScreen;
