import './style.scss'

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";
import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import SelectSearch from 'react-select';
import {getAdv, getChat} from "../../services";
import Adv from "../../components/Adv";

import One from "../../assets/images/1.png";
import Two from "../../assets/images/2.png";
import TreeImg from "../../assets/images/3.png";

const MainScreen = (() => {


    return (
        <Layout>
            <div className="container about">
                <div onClick={() => {

                }} className="btn_pay text text-s18">
                    <div className="btn_pay_icon">
                        <img src={'/pointTop.png'}/>
                    </div>
                    <div className="btn_pay_info">
                        <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                            Топ - запрос на 1 день
                        </p>
                        <p className={'text text-s13'}>
                            Выйти в топ на 1 день
                        </p>
                    </div>
                    <div className="btn_pay_btn">
                        <p className={'text text-s15'}>
                            200 т
                        </p>
                    </div>
                </div>

                <div onClick={() => {

                }} className="btn_pay text text-s18">
                    <div className="btn_pay_icon">
                        <img src={'/pointTop.png'}/>
                    </div>
                    <div className="btn_pay_info">
                        <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                            Топ - запрос на 3 дня
                        </p>
                        <p className={'text text-s13'}>
                            Выйти в топ на 3 дня
                        </p>
                    </div>
                    <div className="btn_pay_btn">
                        <p className={'text text-s15'}>
                            450 т
                        </p>
                    </div>
                </div>

                <div onClick={() => {

                }} className="btn_pay text text-s18">
                    <div className="btn_pay_icon">
                        <img src={'/pointTop.png'}/>
                    </div>
                    <div className="btn_pay_info">
                        <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                            Топ - запрос на 7 дней
                        </p>
                        <p className={'text text-s13'}>
                            Выйти в топ на 7 дней
                        </p>
                    </div>
                    <div className="btn_pay_btn">
                        <p className={'text text-s15'}>
                            900 т
                        </p>
                    </div>
                </div>

                <div onClick={() => {

                }} className="btn_pay text text-s18">
                    <div className="btn_pay_icon">
                        <img src={'/pointVip.png'}/>
                    </div>
                    <div className="btn_pay_info">
                        <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                            VIP - запрос на 3 дня
                        </p>
                        <p className={'text text-s13'}>
                            Стать VIP на 3 дня
                        </p>
                    </div>
                    <div className="btn_pay_btn">
                        <p className={'text text-s15'}>
                            1800 т
                        </p>
                    </div>
                </div>


                <div onClick={() => {

                }} className="btn_pay text text-s18">
                    <div className="btn_pay_icon">
                        <img src={'/pointVip.png'}/>
                    </div>
                    <div className="btn_pay_info">
                        <p className={'text text-s18'} style={{fontWeight: "bold"}}>
                            VIP - запрос на 7 дней
                        </p>
                        <p className={'text text-s13'}>
                            Стать VIP на 7 дней
                        </p>
                    </div>
                    <div className="btn_pay_btn">
                        <p className={'text text-s15'}>
                            3600 т
                        </p>
                    </div>
                </div>

            </div>
        </Layout>
    );
});
export default MainScreen;
