import {useEffect} from "react";

// Хранилище данных
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './redux/index';

// Навигация
import Router from './router';
import {
    BrowserRouter,
} from "react-router-dom";

import 'react-notifications/lib/notifications.css';

import './assets/scss/app.scss'


function App() {

    return (
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate persistor={persistor}
                             loading={
                                 <p>загрузка</p>
                             }>
                    <Router/>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    );
}

export default App;
