import React, {useEffect, useState, useRef} from 'react';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import Modal from 'react-modal';

import SelectSearch from 'react-select';
import slugify from 'react-slugify';

import Switch from 'react-bootstrap-switch';

import {useSelector} from "react-redux";

import StateSlices from "../../redux/slices/State";
import {getAuth, getCategory, getChat, getReg, getUser, resetPass, sendFile, updateDB} from "../../services/index";
import State from "../../redux/actions/StateActions";

const {REACT_APP_ASSETS_PATH} = process.env;


const Layout = (({logo, children, className}) => {

    const state_in = useSelector(StateSlices);
    const messageListRef = useRef(null);

    const [createAdb, setCreateAdb] = useState(false);

    const [openSetting, setOpenSetting] = useState(false);

    const [passModal, setPassModal] = useState(false);
    const [emailReset, setEmailReset] = useState('');

    const [messageChat, setMessageChat] = useState([]);
    const [messageArray, setMessageArray] = useState([]);

    const [sendCodeEmail, setSendCode] = useState(false);
    const [advReg, setAdvReg] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [sendCode, setSenCode] = useState(false);
    const [cityModal, setCity] = useState(false);

    const [openChat, setOpenChat] = useState(false);

    const [codePass, setCodePass] = useState('');
    const [codeNewPass, setNewPass] = useState('');

    const [search, setSearch] = useState('');

    const [imageListAdv, setImageListAdv] = useState([]);


    const [reset, setReset] = useState(false);

    const [scrollUser, setScrollUser] = useState(true);

    const [selectVal, setSelectVal] = useState({
        'type_discont_adv': 'percent', 'open': {label: 'Свободный доступ', value: '002'}
    });

    const [category, setCategory] = useState([]);

    const [resetMessage, setResetMessage] = useState(false);


    const menu = [['Главная страница', '/', true], ['Как пользоваться сервисом', '/vip', true], ['Акции', '/services', true],];

    async function getInfoData() {
        let categoryGet = [...((await getCategory("")).data)];
        setCategory(categoryGet);
    }

    useEffect(() => {
        getInfoData().then();

    }, [reset]);


    const city = ['Астана', 'Алматы', 'АКТАУ', 'АКТОБЕ', 'АЛМАТЫ', 'АТЫРАУ', 'БАЙКОНУР', 'БАЛХАШ', 'БОРОВОЕ', 'ЖЕЗКАЗГАН', 'КАРАГАНДА', 'КОКШЕТАУ', 'КОСТАНАЙ', 'КЫЗЫЛОРДА', 'ПАВЛОДАР', 'ПЕТРОПАВЛОВСК', 'СЕМЕЙ', 'ТАЛДЫКОРГАН', 'ТАРАЗ', 'ТУРКЕСТАН', 'УРАЛЬСК', 'УСТЬ-КАМЕНОГОРСК', 'ШЫМКЕНТ',];

    const city2 = ['Москва', 'Санкт-Петербург', 'Абаза', 'Абакан', 'Абдулино', 'Абинск', 'Агидель', 'Агрыз', 'Адыгейск', 'Азнакаево', 'Азов', 'Ак-Довурак', 'Аксай', 'Алагир', 'Алапаевск', 'Алатырь', 'Алдан', 'Алейск', 'Александров', 'Александровск', 'Александровск-Сахалинский', 'Алексеевка', 'Алексин', 'Алзамай', 'Алупка', 'Алушта'];

    const city3 = ['Киев', 'Одесса',];


    const city_select = [{
        label: 'Казахстан', options: city.map((city_del, index) => {
            const lest = city_del.toLowerCase().split('');
            lest[0] = lest[0].toUpperCase()
            return {label: lest.join(""), value: (index + 1) + 'kz'};
        })
    }, {
        label: 'Россия', options: city2.map((city_del, index) => {
            const lest = city_del.toLowerCase().split('');
            lest[0] = lest[0].toUpperCase()
            return {label: lest.join(""), value: (index + 1) + 'ru'};
        })
    }, {
        label: 'Украина', options: city3.map((city_del, index) => {
            const lest = city_del.toLowerCase().split('');
            lest[0] = lest[0].toUpperCase()
            return {label: lest.join(""), value: (index + 1) + 'uk'};
        })
    },];
    const [cityFilter, setCityFilter] = useState(city_select);
    const [timerMessage, setTimerMessage] = useState(null);


    const select_for = [['Название запроса', 'name', 'text', 'Напр: Мечта: каскадные скидки'], ['Описание группы', 'content', 'textarray', 'Напр: Мечта: каскадные скидки'], ['Город', 'city_id', 'select', 'Выбрать город', city_select, 'adv-w50'], ['Категория товара', 'catalog_id', 'select', 'Выбрать категорию', category.map((iOption) => {
        return {label: iOption.name, value: iOption.id};
    }), 'adv-w50'], ['Дата выкупа', 'date_pay', 'date', '', '', 'adv-w50 adv-w50-first'],];

    const select_for22 = [['Количество участников ', 'player', 'number', '', '', 'adv-w50 '], ['Открытая группа', 'open', 'select', 'Свободный доступ', [{
        label: 'Свободный доступ',
        value: '002'
    }, {
        label: 'С разрешения создателя',
        value: '001'
    },], ''], // ['Количество закупаемого <br> товара', 'product_count', 'number', ''],
        ['Размер скидки в %', 'count_discont', 'label_select', 'Ввести размер скидки в % '], ['Условие скидки', 'condition_discount', 'select', 'Выбрать', ['На всё', 'На самый дешёвый', 'На самый дорогой', 'На второй', 'На третий', 'На четвертый', 'Выставить вручную', 'Выбрать позже',].map((str, index) => {
            return ({label: str, value: (index)})
        })],]

    const auth_step = [{type: 'text', required: true, name: 'name', label: 'ФИО', value: ''}, // {
        //     type: 'select',
        //     required: true,
        //     name: 'city_id',
        //     label: 'Город',
        //     value: '',
        //     placeholder: "Поиск...",
        //     options: city_select
        // },
        // {type: 'date', required: false, name: 'date_id', label: 'Дата рождения', value: ''},
        {
            type: 'text',
            required: true,
            name: 'tel',
            placeholder: '+7 777 777 7777',
            label: 'Телефон',
            value: ''
        }, // {type: 'text', required: false, name: 'iin', label: 'ИИН', value: ''},
        // {type: 'file', required: false, name: 'image', label: 'Фото', value: ''},
        {type: 'text', required: true, name: 'email', label: 'E-mail', value: ''}, {
            type: 'pass',
            required: true,
            name: 'password',
            label: 'Пароль',
            value: ''
        },];

    const auth_step2 = [{type: 'text', name: 'email', label: 'E-mail', value: ''}, {
        type: 'pass',
        name: 'password',
        label: 'Пароль',
        value: ''
    },];


    function showModal() {
        State({city_open: false});
        setLoginModal(false);
        setRegisterModal(false);
        setPassModal(false);

        State({city_open_adv: false});
    }


    // Функция для автоматической прокрутки вниз
    const scrollToBottom = () => {
        const {scrollTop, scrollHeight, clientHeight} = messageListRef.current;
        const isAtBottom = scrollTop + clientHeight === scrollHeight;
        if (scrollUser) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }

    };

    const handleAddMessage = () => {
        scrollToBottom();
    };

    // Обработчик скролла
    const handleScroll = () => {
        const {scrollTop, scrollHeight, clientHeight} = messageListRef.current;
        const isAtBottom = scrollTop + clientHeight === scrollHeight;

        if (isAtBottom) {
            setScrollUser(true);
        } else {
            setScrollUser(false);
            // Запретить автоматическую прокрутку
        }
    };

    useEffect(() => {
        // Проверяем положение прокрутки при загрузке компонента
        handleScroll();
    }, []);


    useEffect(() => {

        if (timerMessage) {
            clearTimeout(timerMessage);
        }

        setTimerMessage(setTimeout(async () => {
            await setMessageChat((await getChat()).data);
            setTimeout(() => {
                handleAddMessage();
            }, 0);
            setResetMessage(!resetMessage)
        }, 1000))

    }, [resetMessage]);

    async function handleSubmit(e) {

        if (state_in?.token) {
            // Prevent the browser from reloading the page
            e.preventDefault();

            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();

            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

            // Read the form data
            const form = e.target;
            const formData = new FormData(form);

            // You can pass formData as a fetch body directly:

            // Or you can work with it as a plain object:
            const formJson = Object.fromEntries(formData.entries());

            await updateDB({
                'save': {
                    comment: formJson.message, user_id: state_in.user.id, advertisement_id: '-1',
                }, 'id': 0, 'model_name': 'Chat',
            });
            await setMessageChat((await getChat()).data);
            setTimeout(() => {
                handleAddMessage();
            }, 0);
            e.target.reset();

        } else {
            alert("Вы не авторизированы")
        }
    }

    return (<>


        {
            openChat && (
                <button className={'show-chat-group'} onClick={() => {
                    setOpenChat(!openChat);
                }}>

                </button>
            )
        }
        <div className={'chat_box_info text ' + (openChat && 'active')}>
            <div className="chat-widget">
                <div onClick={() => {
                    setOpenChat(!openChat);
                }} className="chat-widget-header">
                    <h1 className="header-text text ">Общий чат</h1>
                </div>
                <div ref={messageListRef} onScroll={handleScroll} className="chat-widget-message">

                    {/*{*/}
                    {/*    (!state_in?.token) ? (*/}
                    {/*        <li className={'text text-bold  text-s12'}>*/}
                    {/*            <a className={'text text-s14'} onClick={() => {*/}
                    {/*                showModal();*/}
                    {/*                setLoginModal(true);*/}
                    {/*            }}>Мой профиль</a>*/}
                    {/*            <img style={{width: "0.75rem", marginLeft: "0.5rem"}}*/}
                    {/*                 src={require("./down.png")} alt=""/>*/}
                    {/*        </li>*/}
                    {/*    ) : (*/}
                    {/*        <li className={'text text-bold text-s14'}>*/}
                    {/*            <a href={'/profile'} className={'text text-s12'}>Мой профиль</a>*/}
                    {/*            <img style={{width: "0.75rem", marginLeft: "0.5rem"}}*/}
                    {/*                 src={require("./down.png")} alt=""/>*/}
                    {/*        </li>*/}
                    {/*    )*/}
                    {/*}*/}

                    {messageChat.filter((message) => {
                        return message.advertisement_id == -1;
                    }).map((message) => {

                        var dateObj = new Date(message.created_at);

                        var hours = dateObj.getHours().toString().padStart(2, '0');
                        var minutes = dateObj.getMinutes().toString().padStart(2, '0');

                        var formattedTime = hours + ':' + minutes;

                        if (state_in?.token && state_in.user.id == message.user_id.id) {
                            return (<div className="my-user-message message">
                                <span className="message-time">{formattedTime}</span>
                                <div className="message-content">
                                    <span className="message-username">{message.user_id.name}</span>
                                    <p className="message-text">{message.comment}</p>
                                </div>
                            </div>);
                        }
                        return (
                            <div className="other-user-message">
                                <div className="other-user-avatar" style={{borderRadius: "100%"}}>
                                    <div className="user_message_img"
                                         style={{backgroundImage: "url('https://admin.disconty.kz/" + message.user_id.image + "')"}}>

                                    </div>
                                </div>
                                <div className="message-content">
                                    <span className="message-time">{formattedTime}</span>
                                    <span className="message-username">{message.user_id.name}</span>
                                    <p className="message-text">{message.comment}</p>
                                </div>
                            </div>
                        );

                    })}


                </div>
                <form onSubmit={handleSubmit} className="chat">
                    <input type="text" name={'message'} className="chat-input" placeholder="Написать..."/>
                    <input type={'submit'} className="send-icon"></input>
                </form>

                {/*const [, ] = useState('');*/}
                {/*const [, setMessageArray] = useState('');*/}
            </div>
        </div>

        <Modal
            isOpen={state_in.city_open_adv}
            onRequestClose={showModal}
            contentLabel="Example Modal"
            className="model-add-box"
        >

            <form method={'post'} onSubmit={async (ev) => {
                ev.preventDefault();

                let json = Object.entries(selectVal).filter((isd) => {
                    return isd[0].includes('_adv');
                }).reduce(function (previous, key) {
                    if (key[1]?.label) {
                        if (key[0] == 'catalog_id_adv') {
                            return {...previous, ...{[key[0].replace("_adv", "")]: key[1].value}};
                        }
                        return {...previous, ...{[key[0].replace("_adv", "")]: key[1].label}};
                    }
                    return {...previous, ...{[key[0].replace("_adv", "")]: key[1]}};
                }, {});

                if (!state_in.token) {
                    showModal();
                    NotificationManager.info('Пройдите авторизацию', 'Error');
                    setLoginModal(true);
                } else {

                    json["user_id"] = state_in.user.id;
                    json["save_user"] = advReg;
                    if (typeof json["status"] == "undefined") {
                        json["status"] = 'open';
                    }
                    if (typeof json["open"] == "undefined") {
                        json["open"] = 'Свободный доступ';
                    }
                    if (typeof json["product_count"] == "undefined") {
                        json["product_count"] = 10;
                    }

                    if (imageListAdv.length > 0) {
                        json["images"] = imageListAdv.join(',');
                    }


                    await updateDB({
                        'save': json, 'id': 0, 'model_name': 'Advertisement',
                    });

                    showModal();
                    NotificationManager.success('Данные отправлены на модерацию', '');
                }

            }} className="model-add model-add-head-controls">
                <div className="model-add_head model-add_nav" style={{padding: "0", borderBottom: 0}}>
                    <div className="text text-s22 active btn-nav-link"
                         style={{fontWeight: 500, width: "100%", color: '#333'}}>
                        Добавить запрос
                    </div>
                </div>
                <div className="model-add_body model-add_body-row">
                    {select_for.map((item) => {
                        if (item[2] == 'select') {
                            return (<div className={'input ' + (item.length >= 6 && item[5])}>
                                <p dangerouslySetInnerHTML={{__html: item[0]}}
                                   className="input_lab text text-s14">
                                </p>
                                <SelectSearch options={item[4]} onChange={(test) => {
                                    setSelectVal({
                                        ...{
                                            ...selectVal, ...{
                                                [item[1] + '_adv']: (test)
                                            }
                                        }
                                    });
                                    setReset(!reset);
                                }} search={true}
                                              value={selectVal[item[1] + '_adv']}
                                              name={item[1]}
                                              required={true}
                                              className={'text text-s16'}
                                              placeholder={item[3]}/>
                            </div>)
                        } else if (item[2] == 'date') {
                            return (<div className={'input ' + (item.length >= 6 && item[5])}>
                                <p dangerouslySetInnerHTML={{__html: item[0]}}
                                   className="input_lab text text-s14">
                                </p>
                                <input type="date" min={(new Date().toISOString()).split('T')[0]}
                                       onChange={(test) => {
                                           setSelectVal({
                                               ...{
                                                   ...selectVal, ...{
                                                       [item[1] + '_adv']: (test.target.value)
                                                   }
                                               }
                                           });
                                           setReset(!reset);
                                       }} placeholder={item[3]} required
                                       className="input_in text text-s16"/>
                            </div>)
                        } else if (item[2] == 'label_select') {
                            return (<>
                                <div
                                    className={'input adv-w50 adv-w50-first ' + (item.length >= 6 && item[5])}>
                                    <p dangerouslySetInnerHTML={{__html: 'Формат скидки'}}
                                       className="input_lab text text-s14">
                                    </p>
                                    <select onChange={(text) => {
                                        setSelectVal({
                                            ...selectVal, ...{
                                                ['type_discont_adv']: text.target.value
                                            }
                                        });
                                    }} required={true} style={{width: "100%"}}
                                            className="input_in  text text-s14">
                                        <option value={'percent'}>Скидка в %</option>
                                        <option value={'currency'}>Скидка в тнг</option>
                                    </select>
                                </div>

                                <div className={'input adv-w50 ' + (item.length >= 6 && item[5])}>
                                    <p dangerouslySetInnerHTML={{__html: 'Размер скидки'}}
                                       className="input_lab text text-s14">
                                    </p>
                                    <input type="number"
                                           onChange={(test) => {
                                               setSelectVal({
                                                   ...{
                                                       ...selectVal, ...{
                                                           [item[1] + '_adv']: (test.target.value)
                                                       }
                                                   }
                                               });
                                               setReset(!reset);
                                           }}
                                           placeholder={selectVal['type_discont_adv'] ? (selectVal['type_discont_adv'] == 'percent' ? 'Ввести размер скидки в %' : 'Ввести размер скидки в тенге') : ''}
                                           required
                                           className="input_in text text-s16"/>
                                </div>
                            </>)
                        } else if (item[2] == 'number') {
                            return (<div className={'input ' + (item.length >= 6 && item[5])}>
                                <p dangerouslySetInnerHTML={{__html: item[0]}}
                                   className="input_lab text text-s14">
                                </p>
                                <input onChange={(test) => {
                                    setSelectVal({
                                        ...{
                                            ...selectVal, ...{
                                                [item[1] + '_adv']: (test.target.value)
                                            }
                                        }
                                    });
                                    setReset(!reset);
                                }} type="number" placeholder={item[3]} required
                                       className="input_in text text-s16"/>
                            </div>)
                        } else if (item[2] == 'textarray') {
                            return (<div className={'input ' + (item.length >= 6 && item[5])}>
                                <p dangerouslySetInnerHTML={{__html: item[0]}}
                                   className="input_lab text text-s14">
                                </p>
                                <textarea onChange={(test) => {
                                    setSelectVal({
                                        ...{
                                            ...selectVal, ...{
                                                [item[1] + '_adv']: (test.target.value)
                                            }
                                        }
                                    });
                                    setReset(!reset);
                                }} placeholder={item[3]} required
                                          className="input_in input_in-textar text text-s16"></textarea>
                            </div>);
                        }
                        return (<div className={'input ' + (item.length >= 6 && item[5])}>
                            <p dangerouslySetInnerHTML={{__html: item[0]}}
                               className="input_lab text text-s14">
                            </p>
                            <input onChange={(test) => {
                                setSelectVal({
                                    ...{
                                        ...selectVal, ...{
                                            [item[1] + '_adv']: (test.target.value)
                                        }
                                    }
                                });
                                setReset(!reset);
                            }} type="text" placeholder={item[3]} required
                                   className="input_in text text-s16"/>
                        </div>);
                    })}


                    <div className="nav_box">
                        <div className="pay_discont">
                            <div className="pay_discont__info">
                                <img className={'ok-pro'} src={require('../../assets/images/flag.png')} alt=""/>
                                <div className={'pay_discont__info-text'}>
                                    <p className={'text text-w700 text-s18'}>
                                        Услуга «Купи за меня»
                                    </p>
                                    <p className={'text text-s13'}>
                                        10% от сэкономленной суммы
                                    </p>
                                </div>
                            </div>
                            <div className="pay_discont__ch">
                                <Switch value={advReg} onChange={() => {
                                    setAdvReg(!advReg);
                                }} bsSize={'mini'} name='test'/>
                            </div>
                        </div>
                        {/*<a href="javascript:void(0)" className="btn btn-rek  text text-s16">*/}
                        {/*    Продвигать/Рекламировать*/}
                        {/*</a>*/}

                    </div>

                    <div onClick={() => {
                        setOpenSetting(!openSetting);
                    }} className="ci-setting-open">
                        <p className={'text text-s20'}>
                            <img src={require('../../assets/images/setting.png')} alt=""/> Расширенные настройки
                        </p>
                    </div>

                    {openSetting && (<div className="step-2-open">
                        {select_for22.map((item) => {
                            if (item[2] == 'select') {
                                return (<div className={'input ' + (item.length >= 6 && item[5])}>
                                    <p dangerouslySetInnerHTML={{__html: item[0]}}
                                       className="input_lab text text-s14">
                                    </p>
                                    <SelectSearch options={item[4]} onChange={(test) => {
                                        setSelectVal({
                                            ...{
                                                ...selectVal, ...{
                                                    [item[1] + '_adv']: (test)
                                                }
                                            }
                                        });
                                        setReset(!reset);
                                    }} search={true}
                                                  value={selectVal[item[1] + '_adv']}
                                                  name={item[1]}
                                                  required={true}
                                                  className={'text text-s16'}
                                                  placeholder={item[3]}/>
                                </div>)
                            } else if (item[2] == 'date') {
                                return (<div className={'input ' + (item.length >= 6 && item[5])}>
                                    <p dangerouslySetInnerHTML={{__html: item[0]}}
                                       className="input_lab text text-s14">
                                    </p>
                                    <input type="date" min={(new Date().toISOString()).split('T')[0]}
                                           onChange={(test) => {
                                               setSelectVal({
                                                   ...{
                                                       ...selectVal, ...{
                                                           [item[1] + '_adv']: (test.target.value)
                                                       }
                                                   }
                                               });
                                               setReset(!reset);
                                           }} placeholder={item[3]} required
                                           className="input_in text text-s16"/>
                                </div>)
                            } else if (item[2] == 'label_select') {
                                return (<>
                                    <div
                                        className={'input adv-w50 adv-w50-first ' + (item.length >= 6 && item[5])}>
                                        <p dangerouslySetInnerHTML={{__html: 'Формат скидки'}}
                                           className="input_lab text text-s14">
                                        </p>
                                        <select onChange={(text) => {
                                            setSelectVal({
                                                ...selectVal, ...{
                                                    ['type_discont_adv']: text.target.value
                                                }
                                            });
                                        }} required={true} style={{width: "100%"}}
                                                className="input_in  text text-s14">
                                            <option value={'percent'}>Скидка в %</option>
                                            <option value={'currency'}>Скидка в тнг</option>
                                        </select>
                                    </div>

                                    <div className={'input adv-w50 ' + (item.length >= 6 && item[5])}>
                                        <p dangerouslySetInnerHTML={{__html: 'Размер скидки'}}
                                           className="input_lab text text-s14">
                                        </p>
                                        <input type="number"
                                               onChange={(test) => {
                                                   setSelectVal({
                                                       ...{
                                                           ...selectVal, ...{
                                                               [item[1] + '_adv']: (test.target.value)
                                                           }
                                                       }
                                                   });
                                                   setReset(!reset);
                                               }}
                                               placeholder={selectVal['type_discont_adv'] ? (selectVal['type_discont_adv'] == 'percent' ? 'Ввести размер скидки в %' : 'Ввести размер скидки в тенге') : ''}
                                               required
                                               className="input_in text text-s16"/>
                                    </div>
                                </>)
                            } else if (item[2] == 'number') {
                                return (<div className={'input ' + (item.length >= 6 && item[5])}>
                                    <p dangerouslySetInnerHTML={{__html: item[0]}}
                                       className="input_lab text text-s14">
                                    </p>
                                    <input onChange={(test) => {
                                        setSelectVal({
                                            ...{
                                                ...selectVal, ...{
                                                    [item[1] + '_adv']: (test.target.value)
                                                }
                                            }
                                        });
                                        setReset(!reset);
                                    }} type="number" placeholder={item[3]} required
                                           className="input_in text text-s16"/>
                                </div>)
                            } else if (item[2] == 'textarray') {
                                return (<div className={'input ' + (item.length >= 6 && item[5])}>
                                    <p dangerouslySetInnerHTML={{__html: item[0]}}
                                       className="input_lab text text-s14">
                                    </p>
                                    <textarea onChange={(test) => {
                                        setSelectVal({
                                            ...{
                                                ...selectVal, ...{
                                                    [item[1] + '_adv']: (test.target.value)
                                                }
                                            }
                                        });
                                        setReset(!reset);
                                    }} placeholder={item[3]} required
                                              className="input_in input_in-textar text text-s16"></textarea>
                                </div>);
                            }
                            return (<div className={'input ' + (item.length >= 6 && item[5])}>
                                <p dangerouslySetInnerHTML={{__html: item[0]}}
                                   className="input_lab text text-s14">
                                </p>
                                <input onChange={(test) => {
                                    setSelectVal({
                                        ...{
                                            ...selectVal, ...{
                                                [item[1] + '_adv']: (test.target.value)
                                            }
                                        }
                                    });
                                    setReset(!reset);
                                }} type="text" placeholder={item[3]} required
                                       className="input_in text text-s16"/>
                            </div>);
                        })}


                        <div className={'input '}>
                            <p dangerouslySetInnerHTML={{__html: 'Выбрать фото'}}
                               className="input_lab text text-s14">
                            </p>


                            <div className={'adv-photo-list'}>

                                <div className="adv-photo-list-el">
                                    <img className={'add-img-new'}
                                         src={require('../../assets/images/addImage.jpg')}/>


                                    <input onChange={async (event) => {
                                        var reader = new FileReader();
                                        reader.readAsBinaryString(event.target.files[0]);
                                        reader.onload = async () => {


                                            setImageListAdv([...[...imageListAdv, ...[(await sendFile({
                                                "files": btoa(reader.result)
                                            })).data]]]);
                                        };
                                        reader.onerror = function (error) {
                                            console.log('Error: ', error);
                                        };
                                    }} type="file" name={'images'}
                                           placeholder={'Фото'}
                                           className="input_in text text-s16"/>
                                </div>


                                {imageListAdv.map((item, indexBox) => {


                                    return (<div onClick={() => {
                                        if (confirm("Вы уверены что хотите удалить картинку?")) {
                                            let imageListAdvNew = [...imageListAdv];
                                            imageListAdvNew.splice(indexBox, 1);
                                            setImageListAdv(imageListAdvNew);
                                        }
                                    }} className="adv-photo-list-el">
                                        <img className={'add-img-new'}
                                             src={REACT_APP_ASSETS_PATH + item}/>
                                    </div>);
                                })}

                            </div>


                        </div>

                    </div>)}


                    <div className="nav-btn-form">

                        <button type={'submit'} className="next-auth-form text text-s20">
                            Создать
                        </button>
                        <a href="javascript:void(0)" onClick={() => {
                            showModal();
                        }} className="next-auth-form text text-s20">
                            Отмена
                        </a>
                    </div>
                </div>
            </form>
        </Modal>


        <Modal
            isOpen={registerModal}
            onRequestClose={showModal}
            contentLabel="Example Modal"
            className="model-add-box model-add-box-register"
        >

            <form style={{display: sendCode && 'none'}} method={'post'} onSubmit={async (item) => {

                item.preventDefault()
                let json = Object.entries(selectVal).filter((isd) => {
                    return isd[0].includes('_reg');
                }).reduce(function (previous, key) {
                    return {...previous, ...{[key[0].replace("_reg", "")]: key[1]}};
                }, {});

                if (json?.city_id) {
                    if (json?.city_id?.label) {
                        json['city_id'] = json?.city_id?.label;
                    }
                }

                let reg = (await getReg(json));

                if (reg.success) {
                    NotificationManager.info('Код отправлен на почту ' + json.email);
                    setSenCode(true);
                } else {
                    NotificationManager.error('Почта занята', 'Error');
                }

                return false;

            }} className="model-add">
                <div className="model-add_nav">
                    <a href="javascript:void(0)" onClick={() => {
                        showModal();
                        setLoginModal(true);
                    }} className="btn btn-nav-link text text-s18" style={{fontWeight: 500, color: '#333'}}>
                        Войти
                    </a>
                    <a href="javascript:void(0)" onClick={() => {
                    }} className="btn btn-nav-link text text-s18 active"
                       style={{fontWeight: 500, color: '#333'}}>
                        Зарегистрироваться
                    </a>
                </div>

                <div className="model-add_body">

                    {(auth_step.map((item) => {

                        if (item.type == 'select') {
                            return (<div className="input input-wrap">
                                <p dangerouslySetInnerHTML={{__html: item.label}}
                                   className="input_lab input_lab-full text text-s14">
                                </p>
                                <SelectSearch options={item?.options} required
                                              onChange={(test) => {
                                                  setSelectVal({
                                                      ...{
                                                          ...selectVal, ...{
                                                              [item.name + '_reg']: (test)
                                                          }
                                                      }
                                                  });
                                              }} search={true}
                                              value={selectVal[item.name + '_reg']}
                                              className={'text text-s12'} name={item.name}
                                              placeholder={item?.placeholder}/>

                            </div>)
                        } else if (item.type == 'date') {
                            return (<div className="input input-wrap">
                                <p dangerouslySetInnerHTML={{__html: item.label}}
                                   className="input_lab input_lab-full text text-s14">
                                </p>
                                <input onChange={(ev) => {
                                    setSelectVal({
                                        ...{
                                            ...selectVal, ...{
                                                [item.name + '_reg']: (ev.target.value)
                                            }
                                        }
                                    });
                                }} type="date" placeholder={item?.placeholder}
                                       required={item?.required}
                                       className="input_in text text-s16"/>
                            </div>)
                        } else if (item.type == 'file') {
                            return (<div className="input input-wrap">
                                <p dangerouslySetInnerHTML={{__html: item.label}}
                                   className="input_lab input_lab-full text text-s14">
                                </p>
                                {typeof selectVal[item.name + '_reg'] != 'undefined' && (<img
                                    style={{
                                        width: "10rem", marginBottom: "1rem"
                                    }}
                                    src={'https://admin.disconty.kz' + selectVal[item.name + '_reg']}/>)

                                }
                                <input onChange={async (event) => {
                                    var reader = new FileReader();
                                    reader.readAsBinaryString(event.target.files[0]);
                                    reader.onload = async () => {
                                        setSelectVal({
                                            ...{
                                                ...selectVal, ...{
                                                    [item.name + '_reg']: (await sendFile({
                                                        "files": btoa(reader.result)
                                                    })).data
                                                }
                                            }
                                        });
                                    };
                                    reader.onerror = function (error) {
                                        console.log('Error: ', error);
                                    };
                                }} type="file" name={item.name} required={item?.required}
                                       placeholder={item?.placeholder}
                                       className="input_in text text-s16"/>
                            </div>)
                        } else if (item.type == 'pass') {
                            return (<div className="input input-wrap">
                                <p dangerouslySetInnerHTML={{__html: item.label}}
                                   className="input_lab input_lab-full text text-s14">
                                </p>
                                <input onChange={(ev) => {
                                    setSelectVal({
                                        ...{
                                            ...selectVal, ...{
                                                [item.name + '_reg']: (ev.target.value)
                                            }
                                        }
                                    });
                                }} type="password" required={item?.required} name={item.name}
                                       placeholder={item?.placeholder}

                                       className="input_in text text-s16"/>
                            </div>)
                        }

                        return (<div className="input input-wrap">
                            <p dangerouslySetInnerHTML={{__html: item.label}}
                               className="input_lab input_lab-full text text-s16">
                            </p>
                            <input onChange={(ev) => {
                                setSelectVal({
                                    ...{
                                        ...selectVal, ...{
                                            [item.name + '_reg']: (ev.target.value)
                                        }
                                    }
                                });
                            }} type="text" name={item.name} required={item?.required}
                                   placeholder={item?.placeholder}
                                   className="input_in text text-s16"/>
                        </div>)
                    }))}
                </div>


                <div className="nav-btn-form">
                    <button type={'submit'} style={{backgroundColor: 'transparent', border: "none"}}
                            className={'next-auth-form text text-s20'}>
                        Зарегистрироваться
                    </button>
                    <button onClick={() => {
                        showModal();
                    }} style={{backgroundColor: "transparent", border: 'none'}}
                            className={'next-auth-form text text-s20'}>
                        Отмена
                    </button>
                </div>
            </form>

            <form style={{display: !sendCode && 'none'}} action="" method={'post'}
                  onSubmit={async (item) => {

                      item.preventDefault()
                      let json = Object.entries(selectVal).filter((isd) => {
                          return isd[0].includes('_reg');
                      }).reduce(function (previous, key) {
                          return {...previous, ...{[key[0].replace("_reg", "")]: key[1]}};
                      }, {});

                      let reg = await getReg(json);

                      if (reg.success) {
                          showModal();
                          State({
                              token: reg.data.token,
                          })
                          NotificationManager.success('Вы прошли авторизацию', 'Авторизация');
                          setSenCode(false);
                          State({
                              user: (await (getUser(reg.data.token))).message
                          })
                      } else {
                          NotificationManager.error('Не верный код', 'Error');
                      }
                      return false;
                  }} className={'model-add'}>

                <div className="model-add_nav">
                    <a href="javascript:void(0)" onClick={() => {
                        showModal();
                        setLoginModal(true);
                    }} className="btn btn-nav-link text text-s18" style={{fontWeight: 500, color: '#333'}}>
                        Войти
                    </a>
                    <a href="javascript:void(0)" onClick={() => {
                    }} className="btn btn-nav-link text text-s18 active"
                       style={{fontWeight: 500, color: '#333'}}>
                        Зарегистрироваться
                    </a>
                </div>
                <div className="model-add_body">
                    <div className="input input-wrap" style={{marginTop: (12 / 10) + "rem"}}>
                        <p dangerouslySetInnerHTML={{__html: "Код подтверждения"}}
                           className="input_lab input_lab-full text text-s16">
                        </p>
                        <input value={selectVal?.code_reg} onChange={(ev) => {
                            setSelectVal({
                                ...{
                                    ...selectVal, ...{
                                        ['code_reg']: (ev.target.value)
                                    }
                                }
                            });
                        }} type="text" name={'code'} required
                               placeholder={'Код'}
                               className="input_in text text-s16"/>
                    </div>
                    <div className="nav-btn-form">
                        <button type={'submit'} style={{backgroundColor: 'transparent', border: "none"}}
                                className={'next-auth-form text text-s20'}>
                            Зарегистрироваться
                        </button>
                    </div>
                </div>
            </form>

        </Modal>

        <Modal
            isOpen={loginModal}
            onRequestClose={showModal}
            contentLabel="Example Modal"
            className="model-add-box model-add-box-register"
        >

            <form method={'post'} onSubmit={async (item) => {

                item.preventDefault()
                let json = Object.entries(selectVal).filter((isd) => {
                    return isd[0].includes('_auth');
                }).reduce(function (previous, key) {
                    return {...previous, ...{[key[0].replace("_auth", "")]: key[1]}};
                }, {});

                let reg = await getAuth(json);

                if (reg.success) {
                    showModal();
                    State({
                        token: reg.data.token,
                    })
                    NotificationManager.success('Вы прошли авторизацию', 'Авторизация');
                    setSenCode(false);
                    State({
                        user: (await (getUser(reg.data.token)))?.message
                    })
                } else {
                    NotificationManager.error('Не верный пароль', 'Error');
                }
                return false;
            }} className="model-add">
                <div className="model-add_nav">
                    <a href="javascript:void(0)" onClick={() => {

                    }} className="btn btn-nav-link text text-s18 active" style={{fontWeight: 500, color: '#333'}}>
                        Войти
                    </a>
                    <a href="javascript:void(0)" onClick={() => {
                        setSenCode(false);
                        showModal();
                        setRegisterModal(true);
                    }} className="btn btn-nav-link text text-s18 " style={{fontWeight: 500, color: '#333'}}>
                        Зарегистрироваться
                    </a>
                </div>

                <div
                    className="model-add_body">
                    {auth_step2.map((item) => {

                        if (item.type == 'pass') {
                            return (<div className="input input-wrap">
                                <p dangerouslySetInnerHTML={{__html: item.label}}
                                   className="input_lab input_lab-full text text-s14">
                                </p>
                                <input onChange={(ev) => {
                                    setSelectVal({
                                        ...{
                                            ...selectVal, ...{
                                                [item?.name + '_auth']: (ev.target.value)
                                            }
                                        }
                                    });
                                }} type="password" name={item?.name} placeholder={item?.placeholder}
                                       required
                                       className="input_in text text-s16"/>
                            </div>)
                        }

                        return (<div className="input input-wrap">
                            <p dangerouslySetInnerHTML={{__html: item.label}}
                               className="input_lab input_lab-full text text-s16">
                            </p>
                            <input onChange={(ev) => {
                                setSelectVal({
                                    ...{
                                        ...selectVal, ...{
                                            [item?.name + '_auth']: (ev.target.value)
                                        }
                                    }
                                });
                            }} type="text" name={item?.name} placeholder={item?.placeholder} required
                                   className="input_in text text-s16"/>
                        </div>)
                    })}
                    <a href={"#"} onClick={() => {
                        showModal();
                        setPassModal(true);
                    }} className={'text text-s16'} style={{marginTop: "1rem", display: "flex"}}>
                        Забыл пароль
                    </a>
                </div>
                <div className="nav-btn-form">
                    <button type={'submit'} style={{backgroundColor: "transparent", border: 'none'}}
                            className={'next-auth-form text text-s20'}>
                        Войти
                    </button>
                    <button onClick={() => {
                        showModal();
                    }} style={{backgroundColor: "transparent", border: 'none'}}
                            className={'next-auth-form text text-s20'}>
                        Отмена
                    </button>
                </div>
            </form>
        </Modal>


        <Modal
            isOpen={passModal}
            onRequestClose={showModal}
            contentLabel="Example Modal"
            className="model-add-box model-add-box-register"
        >

            <form method={'post'} className="model-add">
                <div className="model-add_nav">
                    <a href="javascript:void(0)" onClick={() => {
                        showModal();
                        setLoginModal(true);
                    }} className="btn btn-nav-link text text-s18 " style={{fontWeight: 500, color: '#333'}}>
                        Войти
                    </a>
                    <a href="javascript:void(0)" onClick={() => {
                        setSenCode(false);
                        showModal();
                        setRegisterModal(true);
                    }} className="btn btn-nav-link text text-s18 " style={{fontWeight: 500, color: '#333'}}>
                        Зарегистрироваться
                    </a>
                </div>

                <div
                    className="model-add_body">
                    <div className="input input-wrap">
                        <p
                            className="input_lab input_lab-full text text-s14">
                            Email
                        </p>
                        <input disabled={sendCodeEmail} onChange={(ev) => {
                            setEmailReset(ev.target.value)
                        }} type="email" name="email" value={emailReset} placeholder='Email'
                               required
                               className="input_in text text-s16"/>
                    </div>
                </div>

                {sendCodeEmail && (<>
                    <div
                        className="model-add_body">
                        <div className="input input-wrap">
                            <p
                                className="input_lab input_lab-full text text-s14">
                                Код с почты
                            </p>
                            <input onChange={(ev) => {
                                setCodePass(ev.target.value);
                            }} type="number" value={codePass} placeholder='Код'
                                   required
                                   className="input_in text text-s16"/>
                        </div>
                    </div>

                    <div
                        className="model-add_body">
                        <div className="input input-wrap">
                            <p
                                className="input_lab input_lab-full text text-s14">
                                Новый пароль
                            </p>

                            <input onChange={(ev) => {
                                setNewPass(ev.target.value);
                            }} type="text" value={codeNewPass} placeholder='Пароль'
                                   required
                                   className="input_in text text-s16"/>
                        </div>
                    </div>
                </>)}


                <div className="nav-btn-form">

                    <button type={'button'} onClick={async () => {
                        if (!sendCodeEmail) {
                            setSendCode(true);
                            await resetPass({
                                email: emailReset
                            })
                        } else {
                            let result = await resetPass({
                                email: emailReset, code: codePass, password: codeNewPass,
                            })

                            if (result?.success) {
                                setSendCode(false);
                                setEmailReset('');
                                setCodePass('');
                                setNewPass('');
                                showModal();
                                NotificationManager.info('Пароль сброшен', 'Error');
                            } else {
                                NotificationManager.error('Код неверный', 'Error');
                            }

                            // setSendCode(true);
                        }

                    }} style={{backgroundColor: "transparent", border: 'none'}}
                            className={'next-auth-form text text-s20'}>
                        {!sendCodeEmail ? 'отправить код' : 'сбросить пароль'}
                    </button>
                    <button onClick={() => {
                        showModal();
                    }} style={{backgroundColor: "transparent", border: 'none'}}
                            className={'next-auth-form text text-s20'}>
                        Отмена
                    </button>
                </div>
            </form>
        </Modal>


        <Modal
            isOpen={state_in?.city_open}
            onRequestClose={showModal}
            contentLabel="Example Modal"
            className="city-select"
        >
            <div className="city-select-form">
                <div className="container">
                    <div className="city-box-search">
                        <div className="input-search input input-wrap">
                            <p className="input_lab input_lab-full text text-s14">
                                Укажите город, который требуется найти:
                            </p>
                            <input type="text" onChange={(ev) => {

                                let city_filter = [...city_select];
                                city_filter.forEach((val, index) => {
                                    city_filter[index].options = val.options.filter((val2) => {
                                        return val2.label.includes(ev.target.value);
                                    })
                                });

                                city_filter = city_filter.filter((cons) => {
                                    return cons.options.length != 0;
                                });
                                setCityFilter(city_filter);
                            }} placeholder={'Поиск по городам'} required
                                   className="input_in text text-s16"/>
                        </div>

                        <a onClick={() => {
                            showModal();
                        }} className="close-modal text text-s22">✕</a>
                    </div>
                    <div className="city-select-form__list">

                        {cityFilter.length == 0 && (<p className={'text text-18'}>
                            Город не найден
                        </p>)}
                        {cityFilter.map((country) => {
                            return (<div className="country-list">
                                <div className="country-list__title">
                                    <p className={'text text-s14'}>
                                        {country.label}
                                    </p>
                                </div>
                                <div className="country-list__ul">
                                    {country.options.map((optio) => {
                                        return (<a href="javascript:void(0)"
                                                   onClick={() => {
                                                       State({
                                                           city_select: optio.label
                                                       });
                                                       showModal();
                                                   }}
                                                   className="country-list__ul-li text text-s12">
                                            {optio.label}
                                        </a>)
                                    })}
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </Modal>

        <div className={'main-box'}>
            <NotificationContainer/>
            <header className="header">
                <div className="header_main container">
                    <div className="header_main_desk">
                        <a href="/" className="logo">
                            <img src={require('../../assets/images/logo2.png')} alt=""/>
                        </a>
                        <div className="menu_click">
                            <a href="javascript:void(0)" className="menu_icon menu_open">
                                <div></div>
                                <div></div>
                                <div></div>
                            </a>
                        </div>
                        <nav className="nav nav-head" style={{marginRight: "1.3rem"}}>
                            <ul>

                                {menu.map((item) => {
                                    return (<li className={'text text-bold hidden-sm text-s14'}>
                                        <a href={item[1]}>{item[0]}</a>
                                    </li>)
                                })}
                            </ul>
                        </nav>

                        <div className="header-search">

                            <input placeholder="Я ищу... " value={search} onChange={(e) => {
                                setSearch(e.target.value);
                            }} className="header-search__input text text-s14"/>

                            <div className="header-search__submit"></div>
                        </div>

                        <a href="javascript:void(0)" onClick={() => {
                            setImageListAdv([]);
                            State({city_open_adv: true});
                            // alert('Нет доступа')
                        }} className="btn btn-add text text-s14">
                            + Создать группу
                        </a>


                        <a onClick={() => {
                            if (!state_in?.token) {
                                showModal();
                                setLoginModal(true);
                            }
                        }} style={{
                            marginLeft: "1.7rem",
                            width: "2.5rem",
                            height: "2.5rem",
                            marginRight: "1rem",
                            cursor: "pointer"
                        }} className="icon icon-profile">

                            {(state_in?.token && state_in?.user?.image) ? (<img className={'icon_user'}
                                                                                src={"https://admin.disconty.kz" + state_in?.user?.image}
                                                                                alt=""/>) : (
                                <img src={require("./elips.png")} alt=""/>)}
                        </a>
                        <nav className="nav nav-head" style={{flexGrow: 0, cursor: "pointer"}}>
                            <ul>
                                {(!state_in?.token) ? (<li className={'text text-bold  text-s12'}>
                                    <a className={'text text-s14'} onClick={() => {
                                        showModal();
                                        setLoginModal(true);
                                    }}>Мой профиль</a>
                                    <img style={{width: "0.75rem", marginLeft: "0.5rem"}}
                                         src={require("./down.png")} alt=""/>
                                </li>) : (<li className={'text text-bold text-s14'}>
                                    <a href={'/profile'} className={'text text-s12'}>Мой профиль</a>
                                    <img style={{width: "0.75rem", marginLeft: "0.5rem"}}
                                         src={require("./down.png")} alt=""/>
                                </li>)}
                            </ul>
                        </nav>


                    </div>
                </div>
            </header>
            <main>{children}</main>
            <footer className="header header-footer">
                <div className="header_main container">
                    <div className="header_main_desk">
                        <a href="/" className="logo logo-footer">
                            <img src={require('../../assets/images/logo2.png')} alt=""/>
                        </a>
                        <a href="/" className="logo logo-footer-sm">
                            <img src={require('../../assets/images/logo_inv_top.png')} alt=""/>
                        </a>

                        <nav className="nav nav-head">
                            <ul>

                                <li>
                                    <a href={'/of.pdf'}
                                       className={'text text-s12'} style={{marginLeft: "1rem"}}>
                                        Оферта
                                    </a>
                                    <a href={'/pc_v.1.1.pdf'}
                                       className={'text text-s12'} style={{marginLeft: "2rem"}}>
                                        Политика конфиденциальности
                                    </a>
                                    <a href={'/ps_dsiconty_kz_v_1_1.pdf'}
                                       className={'text text-s12'} style={{marginLeft: "2rem"}}>
                                        Пользовательское соглашение
                                    </a>
                                </li>
                            </ul>
                        </nav>

                        <img className={'img_icon'} src={'/icpay.png'}/>
                    </div>
                </div>
            </footer>
        </div>
    </>)
});
export default Layout;
