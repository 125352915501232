import axios from 'axios';
import {NotificationManager} from "react-notifications";

const {REACT_APP_API_PATH} = process.env;

async function request(request, token, type = "") {

    request = Object.assign({
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        method: 'GET',
        url: '',
    }, request);
    if (type == 'file') {
        request = Object.assign({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            method: 'GET',
            url: '',
        }, request);
    }

    request.url = REACT_APP_API_PATH + request.url;

    return await axios(request).then((response) => {
        return response.data;
    }).catch(function (error) {

        return [];
    });
}

export const getReg = async (query) => {

    const req = (await request({
        url: '/auth/mobile/register',
        method: 'POST',
        data: query
    }));
    return req;
}

export const getAuth = async (query) => {

    const req = (await request({
        url: '/auth/mobile/login',
        method: 'POST',
        data: query
    }));
    return req;
}

export const sendFile = async (query) => {

    const req = (await request({
        url: '/update/files',
        method: 'POST',
        data: query
    }, '', 'file'));
    return req;
}


export const updateDB = async (data) => {
    let info = await request({
        method: 'POST',
        url: `/database/update`,
        data: data,
    }, false);

    return info;
}

export const resetPass = async (data) => {
    let info = await request({
        method: 'POST',
        url: `/auth/mobile/pass/ress`,
        data: data,
    }, false);

    return info;
}


export const getPayBox = async (data) => {
    let info = await request({
        method: 'GET',
        url: `/pay_box` + data,
    }, false);

    return info;
}

export const deleteDBAdvUser = async (data) => {
    let info = await request({
        method: 'POST',
        url: `/database/AdvertisementUser/delete`,
        data: data,
    }, false);

    return info;
}
export const deleteDBAdvProduct = async (data) => {
    let info = await request({
        method: 'POST',
        url: `/database/AdvertisementProduct/delete`,
        data: data,
    }, false);

    return info;
}

export const getChat = async (data) => {
    let info = await request({
        method: 'GET',
        url: `/database/get/Chat`,
    }, false);

    return info;
}

export const getAdv = async (data = "") => {
    let info = await request({
        method: 'GET',
        url: `/database/get/Advertisement` + data,
    }, false);

    return info;
}

export const getCategory = async (data = "") => {
    let info = await request({
        method: 'GET',
        url: `/database/get/Catalog` + data,
    }, false);

    return info;
}


export const getAdvOpen = async (id) => {
    let info = await request({
        method: 'GET',
        url: `/database/get/Advertisement/` + id,
    }, false);

    return info;
}

export const getUser = async (token) => {

    const req = (await request({
        url: '/auth/user',
        method: 'GET',
    }, token));
    return req;
}

export const dbCreate = async (token) => {

    const req = (await request({
        url: '/auth/user',
        method: 'GET',
    }, token));
    return req;
}

