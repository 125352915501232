import {configureStore} from '@reduxjs/toolkit'

import {persistStore, persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import {createWhitelistFilter} from 'redux-persist-transform-filter';

// Reducers
import FavoritReducer from './reducers/FavoritReducer';
import StateReducer from './reducers/StateReducer';

const saveSubsetWhitelistFilter = createWhitelistFilter('', []);


const persistConfig = {
    key: 'primary',
    storage: storage,
    transform: [saveSubsetWhitelistFilter],
    blacklist: [],
};

const rootReducer = persistCombineReducers(persistConfig, {
    favorite: FavoritReducer,
    state: StateReducer,
});


export const store = configureStore({
    reducer: { rootReducer },
});
export const persistor = persistStore(store);
