import {
    Routes,
    Route
} from "react-router-dom";


import MainScreen from '../views/MainScreen';
import ProfileScreen from '../views/ProfileScreen';
import AboutScreen from '../views/AboutScreen';
import VipScreen from '../views/VipScreen';
import OfScreen from '../views/OfScreen';
import ServicesScreen from '../views/ServicesScreen';
import AdvScreen from '../views/AdvScreen';


const Router = (() => {
    return (
        <Routes>
            <Route path="/" element={<MainScreen/>}/>
            <Route path="/profile" element={<ProfileScreen/>}/>
            <Route path="/about" element={<AboutScreen/>}/>
            <Route path="/vip" element={<VipScreen/>}/>
            <Route path="/of" element={<OfScreen/>}/>
            <Route path="/services" element={<ServicesScreen/>}/>
            <Route path="/adv/:id" element={<AdvScreen/>}/>
        </Routes>
    )
})
export default Router;
