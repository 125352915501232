import './style.scss'

import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";
import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import SelectSearch from 'react-select';
import {getAdv, getChat} from "../../services";
import Adv from "../../components/Adv";

import One from "../../assets/images/1.png";
import Two from "../../assets/images/2.png";
import TreeImg from "../../assets/images/3.png";

const MainScreen = (() => {


    return (
        <Layout>
            <div className="container about">
                <div className="about_body">
                    <p className={'text text-s15'}>
                        <b>О нас</b> <br/>
                        <br/>
                        Мы - развивающаяся компания ТОО «Disconty», созданы для того, чтобы Вы смогли приобрести
                        интересующие Вас товары
                        и услуги используя коллективные/групповые скидки.
                        <br/><br/>
                        Некоторые продавцы предоставляют своим покупателям значительные скидки при покупке нескольких
                        товаров или оптом.
                        Мы предоставляем Вам площадку «Disconty.kz», чтобы Вы смогли объединиться с такими же
                        потенциальным
                        покупателями
                        как и Вы сами и получить дополнительную скидку из-за того, что покупаете оптом.
                        <br/><br/>
                        Ссылка 2GIS для навигации: <br/>
                        <a style={{color: "#0047FF"}} href="https://2gis.kz/almaty/firm/70000001037726612?m=76.912699%2C43.237473%2F16">
                            https://2gis.kz/almaty/firm/70000001037726612?m=76.912699%2C43.237473%2F16
                        </a>
                        <br/>
                        <br/>
                        Email: <br/>
                        <a style={{color: "#0047FF"}} href="https://2gis.kz/almaty/geo/70000001029135976">
                            disconty.kz@gmail.com
                        </a>
                        <br/>
                        <br/>
                        Телефон: <br/>
                        <a style={{color: "#0047FF"}} href="tel:+77064089065">
                            +7(706)408-90-65
                        </a>
                        <br/>
                        <br/>
                    </p>

                    <iframe className={'maps-about'} src={'https://admin.disconty.kz/public/maps.html'}/>
                </div>
            </div>
        </Layout>
    );
});
export default MainScreen;
